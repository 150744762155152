import React from "react";

import CategoryCard from "./CategoryCard/CategoryCard";

const ListCategories = (props) => {
  return (
    <div className="row justify-content-around">
      {props.categories.map((category) => (
        <div
          // className="col-12 col-lg-6 col-xl-3"
          className="col"
          key={"Category" + category.id}
        >
          <CategoryCard category={category} />
        </div>
      ))}
    </div>
  );
};

export default ListCategories;
