import React from "react";

import SubCategoryCard from "./SubCategoryCard/SubCategoryCard";

const ListSubCategories = (props) => {
  return (
    <div className="row justify-content-around">
      {props.subCategories.map((subCategory) => (
        <div
          className="col-12 col-lg-6 col-xl-3"
          key={"subCategory" + subCategory.id}
        >
          <SubCategoryCard subCategory={subCategory} />
        </div>
      ))}
    </div>
  );
};

export default ListSubCategories;
