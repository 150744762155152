import React from "react";

import classes from "./OrderCard.module.css";
import DetailsSmall from "./DetailsSmall/DetailsSmall";
import DetailsFull from "./DetailsFull/DetailsFull";
import Products from "./Products/Products";

const OrderCard = (props) => {
  const { order, openInPopup, onUpdateOrder, full } = props;

  const getDetails = () => {
    if (full) {
      return <DetailsFull order={order} onUpdateOrder={onUpdateOrder} />;
    } else {
      return <DetailsSmall order={order} openInPopup={openInPopup} />;
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <div className={classes.OrderCard}>
            {getDetails()}
            <Products products={order.products} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(OrderCard);
