import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";

import { API } from "../../../configs/api";
import { AuthContext } from "../../../context/AuthContext";
import ListProducts from "../../ListProducts/ListProducts";

const Wishlist = (props) => {
  const [products, setProducts] = useState([]);
  const wishlist = useContext(AuthContext).wishlist;

  useEffect(() => {
    if (wishlist.length > 0) {
      const formData = new FormData();
      wishlist.forEach((product, index) =>
        formData.append(`ids[${index}]`, product.productId)
      );
      Axios.post(API.products.getProductsByIds, formData)
        .then((response) => {
          
          setProducts(response.data);
        })
        .catch((error) => {});
    } else {
      setProducts([]);
    }
  }, [wishlist]);

  return (
    <div className="container">
      <ListProducts products={products} />
    </div>
  );
};

export default Wishlist;
