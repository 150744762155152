export const Admin_Role = "Admin";
export const Vendor_Role = "Vendor";
export const PENDING_PRODUCTS = "Pending";
export const APPROVED_PRODUCTS = "Approved";
export const REJECTED_PRODUCTS = "Rejected";
export const DELIVERY_MESSAGE =
  "يتم ارسال رابط التحميل الى البريد الإلكتروني خلال 24 ساعة من تأكيد الدفع";
export const PAYMENT_EWALETMETHOD_MESSAGE =
  "سيتم تأكيد الأوردر بعد اتمام عملية الدفع عن طريق تحويل اجمالي المبلغ لرقم: 01145662490 من خلال:";
export const PAYMENT_EWALETMETHOD1 = "اي محفظة الكترونية";
export const PAYMENT_EWALETMETHOD2 = "انستا باي InstaPay";
export const PAYMENT_SEND_WHATSMESSAGE =
  "فور التحويل، برجاء ارسال صورة من ايصال التحويل من خلال رسالة واتس اب WhatsApp على الرقم : 01145662490";
  export const FREEORDER_MESSAGE = "الطلب بالكامل مجاني - رابط التحميل سيتم ارساله الى بريدك الإلكتروني";