export const formatDateYYYYMMDD = (date) => {
  const myDate = new Date(date);
  let formattedDate =
    myDate.getFullYear() +
    "-" +
    ("0" + (myDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + myDate.getDate()).slice(-2);
  return formattedDate;
};

export const getFullDate = (dateString) => {
  let date = new Date(dateString);
  return date.toLocaleString();
};

// Options key examples:
// day:
// The representation of the day.
// Possible values are "numeric", "2-digit".
// weekday:
// The representation of the weekday.
// Possible values are "narrow", "short", "long".
// year:
// The representation of the year.
// Possible values are "numeric", "2-digit".
// month:
// The representation of the month.
// Possible values are "numeric", "2-digit", "narrow", "short", "long".
// hour:
// The representation of the hour.
// Possible values are "numeric", "2-digit".
// minute: The representation of the minute.
// Possible values are "numeric", "2-digit".
// second:
// The representation of the second.
// Possible values are "numeric", 2-digit".

// All these keys are optional.
// You can change the number of options values based on your requirements,
// and this will also reflect the presence of each date time term.

// Note: If you would only like to configure the content options,
// but still use the current locale,
// passing null for the first parameter will cause an error. Use undefined instead.

// You can also use the toLocaleString() method for the same purpose.
// The only difference is this function provides the time when you don't pass any options.
// Example
// 9/17/2016, 1:21:34 PM

// var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
// var today  = new Date();

/*const logDateFunctions = () => {
  let date = new Date();
  console.log(date.toLocaleString());
  console.log("getDate: " + date.getDate());
  console.log("getDay: " + date.getDay());
  console.log("getFullYear: " + date.getFullYear());
  console.log("getHours: " + date.getHours());
  console.log("getMilliseconds: " + date.getMilliseconds());
  console.log("getMinutes: " + date.getMinutes());
  console.log("getMonth: " + date.getMonth());
  console.log("getSeconds: " + date.getSeconds());
  console.log("getTime: " + date.getTime());
  console.log("getTimezoneOffset: " + date.getTimezoneOffset());
  console.log("getUTCDate: " + date.getUTCDate());
  console.log("getUTCDay: " + date.getUTCDay());
  console.log("getUTCFullYear: " + date.getUTCFullYear());
  console.log("getUTCHours: " + date.getUTCHours());
  console.log("getUTCMilliseconds: " + date.getUTCMilliseconds());
  console.log("getUTCMinutes: " + date.getUTCMinutes());
  console.log("getUTCMonth: " + date.getUTCMonth());
  console.log("getUTCSeconds: " + date.getUTCSeconds());
  console.log("toDateString: " + date.toDateString());
  console.log("toISOString: " + date.toISOString());
  console.log("toJSON: " + date.toJSON());
  console.log("toLocaleDateString: " + date.toLocaleDateString());
  console.log("toLocaleString: " + date.toLocaleString());
  console.log("toLocaleTimeString: " + date.toLocaleTimeString());
  console.log("toString: " + date.toString());
  console.log("toTimeString: " + date.toTimeString());
  console.log("toUTCString: " + date.toUTCString());
  console.log("valueOf: " + date.valueOf());
};*/
