import React from 'react';

import classes from './Logo.module.css';
import SlickLogo from '../../assets/images/slickElements-logo.jpg';

const Logo = (props) => {
  return (
    <div className={classes.Logo} style={{height: props.height}}>
      <img src={SlickLogo} alt="Slick Elements"/>
    </div>
  );
}

export default Logo;
