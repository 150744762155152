import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";

import classes from "../MyAccount.module.css";
import { AuthContext } from "../../../../context/AuthContext";
import { ErrorContext } from "../../../../context/ErrorContext";
import { API } from "../../../../configs/api";
import Button from "../../../UI/Button/Button";

const defaultAddress = {
  id: "",
  addressDetails: "",
  nearestLandmark: "",
  cityId: "",
  userId: "",
};

const AddressForm = (props) => {
  const { id } = props.match.params;
  const [editMode, setEditMode] = useState(false);
  const [address, setAddress] = useState(defaultAddress);
  const [cities, setCities] = useState([]);
  const userId = useContext(AuthContext).userData.id;
  const viewError = useContext(ErrorContext).activateError;

  useEffect(() => {
    if (id) {
      if (isNaN(+id) || +id <= 0) {
        props.history.goBack();
      }
      Axios.get(API.addresses.route + id)
        .then((response) => {
          setEditMode(true);
          setAddress(response.data);
        })
        .catch((error) => {
          viewError(true);
        });
    }
  }, [id, props.history, viewError]);

  useEffect(() => {
    Axios.get(API.addresses.cities)
      .then((response) => {
        setCities(response.data);
        
      })
      .catch((error) => {
        viewError(true);
      });
  }, [viewError]);

  useEffect(() => {
    if (userId && address.userId) {
      if (userId !== address.userId) {
        props.history.goBack();
      }
    }
  }, [userId, address.userId, props.history]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setAddress({
      ...address,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("addressDetails", address.addressDetails);
    formData.append("nearestLandmark", address.nearestLandmark);
    formData.append("cityId", address.cityId);
    formData.append("userId", userId);

    if (!editMode) {
      Axios.post(API.addresses.route, formData)
        .then((response) => {
          
          const currentPath = props.location.pathname;
          const parentPath = currentPath.substring(
            0,
            currentPath.lastIndexOf("/")
          );
          props.history.push(parentPath);
        })
        .catch((error) => {
          viewError(true);
        });
    } else {
      formData.append("id", address.id);
      Axios.put(API.addresses.route + id, formData)
        .then((response) => {
          
          const currentPath = props.location.pathname;
          const parentPath = currentPath.substring(
            0,
            currentPath.lastIndexOf("/")
          );
          props.history.push(parentPath);
        })
        .catch((error) => {
          viewError(true);
        });
    }
  };

  return (
    <div className={classes.MyAccount}>
      <div className="mb-5">
        <h2>{editMode ? "Update Address" : "Add Address"}</h2>
      </div>
      <div className="inputsContainer row">
        <div className="col-12">
          <form onSubmit={handleSubmit}>
            <div className="row form-group">
              <div className="col">
                <label htmlFor="addressDetails">
                  <strong>Address Details*</strong>
                </label>
                <input
                  type="text"
                  id="addressDetails"
                  name="addressDetails"
                  className="form-control"
                  placeholder="Address Details"
                  required
                  value={address.addressDetails}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-12 col-md-6">
                <label htmlFor="nearestLandmark">
                  <strong>Nearest Landmark*</strong>
                </label>
                <input
                  type="text"
                  id="nearestLandmark"
                  name="nearestLandmark"
                  className="form-control"
                  placeholder="Nearest Landmark"
                  required
                  value={address.nearestLandmark}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-6">
                <label htmlFor="cityId">
                  <strong>City</strong>
                </label>
                <select
                  id="cityId"
                  name="cityId"
                  className="form-control"
                  required
                  value={address.cityId}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Choose...
                  </option>
                  {cities.map((city) => (
                    <option key={"city-" + city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row pt-5">
              <div className="col">
                <Button type="submit">
                  <strong>{editMode ? "Update Address" : "Add Address"}</strong>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddressForm;
