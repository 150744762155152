import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; 
import ReactGA from "react-ga4";

const usePageTracking = () => {
  

  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
      ReactGA.initialize("G-F5GQYNJ6GG");
    //if (!window.location.href.includes("localhost")) {
    //}
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      console.log(document.title)
      console.log(location.pathname)

      ReactGA.send({ hitType: "pageview", page: location.pathname, title: document.title });
      //ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
};

export default usePageTracking;