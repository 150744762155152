import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";

import classes from "../Admin.module.css";
import { API } from "../../../configs/api";
import { ErrorContext } from "../../../context/ErrorContext";
import Button from "../../UI/Button/Button";
import Loading from "../../UI/Loading/Loading";

const initialStoreInfo = {
  id: "",
  email: "",
  phone: "",
  location: "",
  about: "",
  terms: "",
  facebookLink: "",
  twitterLink: "",
  instagramLink: "",
};

const StoreInfo = () => {
  const [storeInfo, setStoreInfo] = useState(initialStoreInfo);
  const viewError = useContext(ErrorContext).activateError;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Axios.get(API.storeInfo.route)
      .then((response) => {
        const info = { ...response.data };
        if (!info.facebookLink) {
          info.facebookLink = "";
        }
        if (!info.twitterLink) {
          info.twitterLink = "";
        }
        if (!info.instagramLink) {
          info.instagramLink = "";
        }
        setStoreInfo(info);
      })
      .catch((error) => {
        viewError(true);
      });
  }, [viewError]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setStoreInfo({
      ...storeInfo,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("id", storeInfo.id);
    formData.append("email", storeInfo.email);
    formData.append("phone", storeInfo.phone);
    formData.append("location", storeInfo.location);
    formData.append("about", storeInfo.about);
    formData.append("terms", storeInfo.terms);
    formData.append("facebookLink", storeInfo.facebookLink);
    formData.append("twitterLink", storeInfo.twitterLink);
    formData.append("instagramLink", storeInfo.instagramLink);

    Axios.put(API.storeInfo.route + storeInfo.id, formData)
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        viewError(true);
      });
  };

  return (
    <div className={classes.AdminForm}>
      <div>
        <h2>Store Info</h2>
      </div>
      <div className="inputsContainer row">
        <div className="col-12">
          <form onSubmit={handleSubmit}>
            <div className="row pt-5">
              <div className="col-6 form-group">
                <label htmlFor="email">
                  <strong>Email</strong>
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Email"
                  className="form-control"
                  value={storeInfo.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-6 form-group">
                <label htmlFor="phone">
                  <strong>Phone Numebr</strong>
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="Phone"
                  className="form-control"
                  value={storeInfo.phone}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-6 form-group">
                <label htmlFor="location">
                  <strong>Location</strong>
                </label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  placeholder="Location"
                  className="form-control"
                  value={storeInfo.location}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-6 form-group">
                <label htmlFor="facebookLink">
                  <strong>Facebook Link</strong>
                </label>
                <input
                  type="text"
                  id="facebookLink"
                  name="facebookLink"
                  className="form-control"
                  value={storeInfo.facebookLink}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-6 form-group">
                <label htmlFor="twitterLink">
                  <strong>Twitter Link</strong>
                </label>
                <input
                  type="text"
                  id="twitterLink"
                  name="twitterLink"
                  className="form-control"
                  value={storeInfo.twitterLink}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-6 form-group">
                <label htmlFor="instagramLink">
                  <strong>Instagram Link</strong>
                </label>
                <input
                  type="text"
                  id="instagramLink"
                  name="instagramLink"
                  className="form-control"
                  value={storeInfo.instagramLink}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-9 form-group">
                <label htmlFor="about">
                  <strong>About</strong>
                </label>
                <textarea
                  id="about"
                  name="about"
                  placeholder="About"
                  className="form-control"
                  value={storeInfo.about}
                  onChange={handleChange}
                  required
                  rows={3}
                />
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-9 form-group">
                <label htmlFor="terms">
                  <strong>Terms of service</strong>
                </label>
                <textarea
                  id="terms"
                  name="terms"
                  placeholder="Terms of service"
                  className="form-control"
                  style={{
                    textAlign: "start",
                    unicodeBidi: "plaintext",
                  }}
                  value={storeInfo.terms}
                  onChange={handleChange}
                  required
                  rows={3}
                />
              </div>
            </div>
            <div className="row pt-5 justify-content-center">
              <div className="col-12 text-center">
                <Button type="submit">
                  <strong>Save</strong>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default React.memo(StoreInfo);
