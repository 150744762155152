import React, { useEffect, useContext } from "react";
import Axios from "axios";
import { ThemeProvider, createTheme , CssBaseline } from "@material-ui/core";

import { API } from "./configs/api";
import { AuthContext } from "./context/AuthContext";
import { CartContext } from "./context/CartContext";
import Layout from "./containers/Layout/Layout";
import usePageTracking from "./usePageTracking";

const theme = createTheme ({
  typography: {
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      main: "#333996",
      light: "#3c44b126",
    },
    secondary: {
      main: "#f83245",
      light: "#f8324526",
    },
    background: {
      default: "#f4f5fd",
    },
  },
  overrides: {
    // name of component
    MuiAppBar: {
      // the specific compoenent to apply class for
      root: {
        // transform: "translateZ(0)",
      },
    },
  },
  props: {
    // name of compoenent
    MuiIconButton: {
      // disableRipple: true,
    },
  },
});

const App = () => {
  const userId = useContext(AuthContext).userData.id;
  const login = useContext(AuthContext).manualLogin;
  const logout = useContext(AuthContext).logout;
  const setCart = useContext(CartContext).setCart;
  const clearCart = useContext(CartContext).clearCart;
  const setWishlist = useContext(AuthContext).setWishlist;
  usePageTracking()
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const expDate = localStorage.getItem("expirationDate");
      if (expDate > Date.now()) {
        login(token);
      } else {
        logout();
        clearCart();
        setWishlist([]);
      }
    } else {
      logout();
    }
  }, [login, logout, clearCart, setWishlist]);

  useEffect(() => {
    if (userId) {
      clearCart();
      // get user cart
      Axios.get(API.users.getUserCart + userId)
        .then((response) => {
          setCart(response.data);
        })
        .catch((error) => {});
      // get user wishlist
      Axios.get(API.users.getUserWishlist + userId)
        .then((response) => {
          setWishlist(response.data);
        })
        .catch((error) => {});
    } else {
      const cart = JSON.parse(localStorage.getItem("cart"));
      if (cart) {
        setCart(cart);
      }
    }
  }, [userId, clearCart, setCart, setWishlist]);

  return (
    <ThemeProvider theme={theme}>
      <Layout />
      <CssBaseline />
    </ThemeProvider>
  );
};

export default App;
