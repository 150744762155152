const api = {
  storeInfo: {
    route: "api/storeInfo/",
  },
  featuredImages: {
    route: "api/featuredImages/",
  },
  categories: {
    route: "api/categories/",
    names: "api/categories/GetCategoriesNames/",
    withSub: "api/categories/GetCategoriesWithSub/",
  },
  subCategories: {
    route: "api/subcategories/",
    getByCategoryId: "api/subcategories/GetByCategoryId/",
    names: "api/subcategories/GetSubCategoriesNames/",
  },
  products: {
    route: "api/products/",
    getProductsByIds: "api/products/GetProductsByIds/",
    GetProductsByIdsFull: "api/products/GetProductsByIdsFull/",
    GetProductFull: "api/products/GetProductByIdFull/",
    getLatestProducts: "api/products/GetLatestProducts/",
    getByCategoryId: "api/Products/GetByCategoryId/",
    getBySubCategoryId: "api/Products/GetBySubCategoryId/",
    getByVendroId: "api/Products/GetByVendorId/",
    GetByVendorIdFull: "api/Products/GetByVendorIdFull/",
    updateProductVendor: "api/products/UpdateProductVendor/",
    updateProductStatus: "api/products/UpdateProductStatus/",
    deleteProductPhoto: "api/Products/DeleteProductPhoto/",
    getProductStatuses: "api/products/GetProductStatuses/",
    sizes: "api/products/GetAllSizes/",
    tags: "api/products/GetAllTags/",
    tagsByName: "api/products/GetTagsByName/",
    deleteTag: "api/products/DeleteTag",
  },
  users: {
    getUsers: "api/Users/GetUsers/",
    getUser: "api/Users/GetUser/",
    getVendors: "api/Users/GetVendors/",
    register: "api/Users/Register/",
    login: "api/Users/Login/",
    update: "api/Users/PutUser/",
    AssignRoles: "api/Users/AssignRoles/",
    delete: "api/Users/DeleteUser/",
    changePassword: "api/Users/ChangePassword/",
    getAllRoles: "api/Users/GetAllRoles/",
    getUserCart: "api/Users/GetUserCart/",
    addToCart: "api/Users/AddToCart/",
    updateCartProduct: "api/Users/UpdateCartProduct/",
    removeFromCart: "api/Users/RemoveFromCart/",
    clearUserCart: "api/Users/ClearUserCart/",
    getUserWishlist: "api/Users/GetUserWishlist/",
    addToWishlist: "api/Users/AddToWishlist/",
    removeFromWishlist: "api/Users/RemoveFromWishlist/",
  },
  addresses: {
    route: "api/Addresses/",
    getUserAddresses: "api/Addresses/GetUserAddresses/",
    cities: "api/Addresses/GetAllCities/",
    updateCityShipping: "api/Addresses/UpdateCityShipping/",
  },
  orders: {
    route: "api/orders/",
    getOrderStatuses: "api/orders/GetOrderStatuses/",
    updateOrderStatus: "api/orders/UpdateOrderStatus/",
    orderCanceled: "api/orders/OrderCanceled/",
    getUserOrders: "api/orders/GetUserOrders/",
  },
};

const DEVELOPMENT_URL = "https://localhost:44301/";
const PRODUCTION_URL = "https://slickelements.com:4433/";
const baseURL =
  process.env.NODE_ENV === "production" ? PRODUCTION_URL : DEVELOPMENT_URL;

export const API = api;
export const BASE_URL = baseURL;
