import React, { useContext } from "react";
import { Link, withRouter } from "react-router-dom";

import classes from "./TopHeader.module.css";
import { AuthContext } from "../../../context/AuthContext";
import { CartContext } from "../../../context/CartContext";
import { Vendor_Role } from "../../../configs/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faHeart } from "@fortawesome/free-solid-svg-icons";

const TopHeader = (props) => {
  const { storeInfo, isAuthenticated, isAdmin } = props;
  const clearCart = useContext(CartContext).clearCart;
  const logout = useContext(AuthContext).logout;
  const isVendor = useContext(AuthContext).userData.roles.includes(Vendor_Role);

  const logoutHandler = () => {
    clearCart();
    logout();
    props.history.push("/");
  };

  return (
    <div className={classes.TopHeader}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 text-left">
            <ul className="pl-5">
              <li>Welcome To Slick Elements - Stock Footage.</li>
              <li>
                {isAuthenticated && isAdmin ? (
                  props.location.pathname.includes("/admin") ? (
                    <Link className={classes.Item} to="/">
                      Return to Home page
                    </Link>
                  ) : (
                    <Link className={classes.Item} to="/admin">
                      Go to Admin Panel
                    </Link>
                  )
                ) : (
                  "Call Us: " + storeInfo.phone
                )}
              </li>
            </ul>
          </div>
          <div className="col-6">
            <ul className="justify-content-end pr-5">
              {isAuthenticated ? (
                <li>
                  <Link className={classes.Item} to="/myaccount/wishlist">
                    <span className="pr-2">
                      <FontAwesomeIcon icon={faHeart} />
                    </span>
                    Wishlist
                  </Link>
                </li>
              ) : null}
              <li className={classes.Dropdown}>
                <button
                  className={[classes.Item, classes.BtnDropdown].join(" ")}
                  type="button"
                >
                  <span className="pr-2">
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  My Account
                </button>
                <div className={classes.DropdownContentWrapper}>
                  {isAuthenticated ? (
                    <ul className={classes.DropdownContent}>
                      <li>
                        <Link className={classes.Item} to="/myaccount">
                          My Profile
                        </Link>
                      </li>
                      <li>
                        {isVendor ? (
                          <Link
                            className={classes.Item}
                            to="/myvendor"
                          >
                            My Vendor Profile
                          </Link>
                        ) : (
                          <Link
                            className={classes.Item}
                            to="/myaccount/myorders"
                          >
                            My Orders
                          </Link>
                        )}
                      </li>
                      <li>
                        <button
                          type="button"
                          className={classes.Item}
                          onClick={logoutHandler}
                        >
                          Logout
                        </button>
                      </li>
                    </ul>
                  ) : (
                    <ul className={classes.DropdownContent}>
                      <li>
                        <Link className={classes.Item} to="/auth/login">
                          Login
                        </Link>
                      </li>
                      <li>
                        <Link className={classes.Item} to="/auth/register">
                          Register
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(React.memo(TopHeader));
