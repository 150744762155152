import React from 'react';
import { withRouter } from 'react-router-dom';

class GoogleAnalytics extends React.Component {
    componentWillUpdate ({ location, history }) {
        const gtag = window.gtag;
        console.log('jjjel')

        if (location.pathname === this.props.location.pathname) {
            // don't log identical link clicks (nav links likely)
            return;
        }

        if (history.action === 'PUSH' &&
            typeof(gtag) === 'function') {
                console.log('3333222')
            gtag('config', 'G-F5GQYNJ6GG', {
                'page_title': document.title,
                'page_location': window.location.href,
                'page_path': location.pathname
            });
        }
    }
    componentDidUpdate() {
        console.log("Updated!");
    }
    render () {
        return null;
    }
}

export default withRouter(GoogleAnalytics);