import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";

import classes from "../../Admin.module.css";
import { API } from "../../../../configs/api";
import { ErrorContext } from "../../../../context/ErrorContext";
import defaultImage from "../../../../assets/images/imgplaceholder.jpg";
import Button from "../../../UI/Button/Button";
import Loading from "../../../UI/Loading/Loading";

const initialSubCategory = {
  id: "",
  name: "",
  category: {
    id: "",
    name: "",
  },
  cover: null,
  coverSrc: defaultImage,
};

const SubCategoryForm = (props) => {
  const { id } = props.match.params;
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subCategory, setSubCategory] = useState(initialSubCategory);
  const [categories, setCategories] = useState([]);
  const viewError = useContext(ErrorContext).activateError;

  useEffect(() => {
    if (id) {
      if (isNaN(+id) || +id <= 0) {
        props.history.goBack();
      }

      Axios.get(API.subCategories.route + id)
        .then((response) => {
          setEditMode(true);
          setSubCategory({
            id: response.data.id,
            name: response.data.name,
            category: response.data.category,
            cover: response.data.cover,
            coverSrc: response.data.cover,
          });
        })
        .catch((error) => {
          viewError(true);
        });
    }
  }, [viewError, props.history, id]);

  useEffect(() => {
    Axios.get(API.categories.names)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        viewError(true);
      });
  }, [viewError]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "category") {
      setSubCategory({
        ...subCategory,
        category: {
          ...subCategory.category,
          id: value,
          name: e.target.options[e.target.selectedIndex].text,
        },
      });
    } else {
      setSubCategory({
        ...subCategory,
        [name]: value,
      });
    }
  };

  const showPreview = (e) => {
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (file) => {
        if (reader.readyState === 2) {
          setSubCategory({
            ...subCategory,
            cover: imageFile,
            coverSrc: file.target.result,
          });
        }
      };
      reader.readAsDataURL(imageFile);
    } else {
      setSubCategory({
        ...subCategory,
        cover: null,
        coverSrc: defaultImage,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("name", subCategory.name);
    formData.append("category.id", subCategory.category.id);
    formData.append("photoFile", subCategory.cover);
    if (!editMode) {
      Axios.post(API.subCategories.route, formData)
        .then((response) => {
          setLoading(false);
          const currentPath = props.location.pathname;
          const parentPath = currentPath.substring(
            0,
            currentPath.lastIndexOf("/")
          );
          props.history.push(parentPath);
        })
        .catch((error) => {
          setLoading(false);
          viewError(true);
        });
    } else {
      formData.append("id", subCategory.id);
      Axios.put(API.subCategories.route + subCategory.id, formData)
        .then((response) => {
          setLoading(false);
          const currentPath = props.location.pathname;
          const parentPath = currentPath.substring(
            0,
            currentPath.lastIndexOf("/")
          );
          props.history.push(parentPath);
        })
        .catch((error) => {
          setLoading(false);
          viewError(true);
        });
    }
  };

  return (
    <div className={classes.AdminForm}>
      <div>
        <h2>Add New Sub Category</h2>
      </div>
      <div className="inputsContainer row">
        <div className="col-12">
          <form onSubmit={handleSubmit}>
            <div className="row pt-5 justify-content-around align-items-center">
              <div className="col-4 form-group">
                <div className="row">
                  <div className="col-12 p-3">
                    <label htmlFor="name">
                      <strong>Name</strong>
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      className="form-control"
                      value={subCategory.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12 p-3">
                    <label htmlFor="category">
                      <strong>Category</strong>
                    </label>
                    <select
                      id="category"
                      name="category"
                      className="form-control"
                      value={subCategory.category.id}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>
                        Choose...
                      </option>
                      {categories.map((category) => (
                        <option
                          key={"category-" + category.id}
                          value={category.id}
                        >
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-7">
                <div className="card text-center">
                  <div className={classes.CoverCard}>
                    <img
                      src={subCategory.coverSrc}
                      className="card-img-top"
                      alt="sub category cover"
                    />
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="cover">
                        <strong>Cover</strong>
                      </label>
                      <input
                        type="file"
                        id="cover"
                        name="cover"
                        accept=".jpg, .jpeg, .png"
                        className="form-control-file"
                        onChange={showPreview}
                        required={!editMode}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-5 justify-content-center">
              <div className="col-12 text-center">
                <Button type="submit">
                  <strong>
                    {editMode ? "Update Sub Category" : "Add Sub Category"}
                  </strong>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default SubCategoryForm;
