import React from "react";
import { Link } from "react-router-dom";

import classes from "./Footer.module.css";
import Logo from "../Logo/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

const Footer = (props) => {
  const { storeInfo, categories } = props;

  return (
    <div className={classes.Footer}>
      <div className="container-fluid p-5">
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className={classes.About}>
              <div className="pb-3">
                <Logo height="10rem" />
              </div>
              <div className="pb-3">
                <p>{storeInfo.about}</p>
              </div>
              <div className={classes.SocialMedia}>
                <ul className="d-flex">
                  {storeInfo.facebookLink && (
                    <li>
                      <a
                        href={storeInfo.facebookLink}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>
                    </li>
                  )}
                  {storeInfo.twitterLink && (
                    <li>
                      <a
                        href={storeInfo.twitterLink}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                    </li>
                  )}
                  {storeInfo.instagramLink && (
                    <li>
                      <a
                        href={storeInfo.instagramLink}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <div className={["row", classes.Links].join(" ")}>
              <div className="col-12 col-md mb-5">
                <h2>Categories</h2>
                <ul>
                  {categories.map((category) => (
                    <li key={"category-" + category.id}>
                      <Link to={"/category/" + category.id}>
                        {category.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-12 col-md mb-5">
                <h2>Contact Us</h2>
                <ul>
                  <li>
                    <Link to="#">Call Us: {storeInfo.phone}</Link>
                  </li>
                  <li>
                    <Link to="#">Email Us: {storeInfo.email}</Link>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md mb-5 -none">
                <h2>About Us</h2>
                <ul>
                  <li>
                    <Link to="#">Location: {storeInfo.location}</Link>
                  </li>
                  <li>
                    <Link to="/termsofservice">Terms of service</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className={["col", "-none", classes.Copyrights].join(" ")}>
          © 2023 Slick Elements. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
