import React, { useState, useEffect } from "react";
import Axios from "axios";

import classes from "../Admin.module.css";
import { API } from "../../../configs/api";
import Notification from "../../Templates/Notification";
import Button from "../../UI/Button/Button";
import Loading from "../../UI/Loading/Loading";

const ShippingForm = (props) => {
  const [city, setCity] = useState({
    id: 0,
    shipping: 0,
  });
  const [cities, setCities] = useState([]);
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Axios.get(API.addresses.cities)
      .then((response) => {
        setCities(response.data);
        setCity(response.data[0]);
      })
      .catch((error) => {
        setNotification({
          isOpen: true,
          message: "Something went Wrong",
          type: "warning",
        });
      });
  }, [notification]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "id") {
      let selectedCity = cities.find((city) => city.id === +value);
      setCity({ ...selectedCity });
    } else {
      setCity({
        ...city,
        [name]: +value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("shipping", city.shipping);

    Axios.put(API.addresses.updateCityShipping + city.id, formData)
      .then((response) => {
        setLoading(false);
        setNotification({
          isOpen: true,
          message: "Updated Successfully",
          type: "success",
        });
      })
      .catch((error) => {
        setLoading(false);
        setNotification({
          isOpen: true,
          message: "Something went Wrong",
          type: "warning",
        });
      });
  };

  return (
    <div className={classes.AdminForm}>
      <div>
        <h2>Update Shipping Fees</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="inputsContainer row mt-5">
          <div className="col">
            <label htmlFor="cityID">
              <strong>City</strong>
            </label>
            <select
              id="cityID"
              name="id"
              className="form-control"
              value={city.id}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                --Select--
              </option>
              {cities.map((city) => (
                <option key={"city-" + city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col">
            <label htmlFor="shipping">
              <strong>Fees</strong>
            </label>
            <input
              type="number"
              id="shipping"
              name="shipping"
              className="form-control"
              min="0"
              value={city.shipping}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col text-center m-5">
            <Button type="submit">
              <strong>Update</strong>
            </Button>
          </div>
        </div>
      </form>
      <Notification
        notification={notification}
        setNotification={setNotification}
      />
      {loading && <Loading />}
    </div>
  );
};

export default ShippingForm;
