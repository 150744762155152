import React from "react";

import ProductCard from "./ProductCard/ProductCard";

const ListProducts = (props) => {
  return (
    <div className="row justify-content-around">
      {props.products.map((product) => (
        <div className="col-12 col-lg-6 col-xl-3" key={"product-" + product.id}>
          <ProductCard product={product} />
        </div>
      ))}
    </div>
  );
};

export default ListProducts;
