import React from "react";
import { Link } from "react-router-dom";

import classes from "./AreaBar.module.css";

const AreaBar = (props) => {
  return (
    <section className={classes.AreaBar}>
      <div>
        <h2>{props.title}</h2>
      </div>
      <div>
        <h3>
          <Link to="/">Home</Link> <span>/ {props.area}</span>
        </h3>
      </div>
    </section>
  );
};

export default AreaBar;
