import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ActionButton } from "../Controls";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    paddingRight: "0px",
  },
}));

const Popup = (props) => {
  const { title, children, openPopup, setOpenPopup } = props;
  const muiClasses = useStyles();

  return (
    <Dialog
      open={openPopup}
      maxWidth="xl"
      classes={{ paper: muiClasses.dialogWrapper }}
    >
      <DialogTitle className={muiClasses.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <ActionButton
            color="secondary"
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            <Close />
          </ActionButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
};

export default React.memo(Popup);
