import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Axios from "axios";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import { BASE_URL } from "./configs/api";
import ErrorContextProvider from "./context/ErrorContext";
import AuthContextProvider from "./context/AuthContext";
import CartContextProvider from "./context/CartContext";
import * as serviceWorker from "./serviceWorker";
import GoogleAnalytics from './he';

Axios.defaults.baseURL = BASE_URL;
// Axios.defaults.headers.post["Content-Type"] = "application/json";
const injectGA = () => {
  if (typeof window == 'undefined') {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());

  //gtag('config', 'G-F5GQYNJ6GG');
};
ReactDOM.render(
  <React.StrictMode>
    <ErrorContextProvider>
      <AuthContextProvider>
        <CartContextProvider>
          <BrowserRouter>
            
            <App />
            
          </BrowserRouter>
        </CartContextProvider>
      </AuthContextProvider>
    </ErrorContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
