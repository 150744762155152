import React, { useState, useEffect, useContext, useCallback } from "react";
import Axios from "axios";
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
} from "@material-ui/core";

import classes from "../Admin.module.css";
import { API } from "../../../configs/api";
import { ErrorContext } from "../../../context/ErrorContext";
import UserForm from "./UserForm";
import Popup from "../../Templates/Popup";
import useTable from "../../Templates/useTable";
import Notification from "../../Templates/Notification";
import ConfirmDialog from "../../Templates/ConfirmDialog";
import { Input, ActionButton } from "../../Controls";
import Loading from "../../UI/Loading/Loading";
import { Search, Edit, DeleteForever } from "@material-ui/icons";

const headCells = [
  { id: "#", label: "#", disableSorting: true },
  { id: "firstName", label: "Name" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone" },
  { id: "address", label: "Address", disableSorting: true },
  { id: "roles", label: "Roles", disableSorting: true },
  { id: "actions", label: "", disableSorting: true },
];

const ListUsers = (props) => {
  const [users, setUsers] = useState([]);
  const [userToEdit, setUserToEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const viewError = useContext(ErrorContext).activateError;
  const [loading, setLoading] = useState(false);

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    setPage,
  } = useTable(users, headCells, filterFn);

  const updateUsers = useCallback(() => {
    Axios.get(API.users.getUsers)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        viewError(true);
      });
  }, [viewError]);

  useEffect(() => {
    updateUsers();
  }, [updateUsers]);

  const openInPopup = (item) => {
    setUserToEdit(item);
    setOpenPopup(true);
  };

  const handleSearch = (e) => {
    let value = e.target.value.toLowerCase();
    setFilterFn({
      fn: (items) => {
        if (value === "") {
          return items;
        } else {
          return items.filter(
            (item) =>
              String(item.firstName).toLowerCase().includes(value) ||
              String(item.lastName).toLowerCase().includes(value) ||
              String(item.email).toLowerCase().includes(value) ||
              String(item.phone).toLowerCase().includes(value) ||
              item.roles.some((role) => role.toLowerCase().includes(value))
          );
        }
      },
    });
    setPage(0);
  };

  const handleDelete = (id) => {
    setLoading(true);
    Axios.delete(API.users.delete + id)
      .then((response) => {
        setLoading(false);
        const newUsers = [...users];
        const deletedIndex = users.findIndex((user) => user.id === id);
        newUsers.splice(deletedIndex, 1);
        setUsers(newUsers);

        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setNotification({
          isOpen: true,
          message: "Deleted Successfully",
          type: "error",
        });
      })
      .catch((error) => {
        setLoading(false);
        viewError(true);
      });
  };

  const onUpdateUser = () => {
    setOpenPopup(false);
    setNotification({
      isOpen: true,
      message: "Updated Successfully",
      type: "success",
    });
    updateUsers();
  };

  return (
    <div className={classes.AdminList}>
      <Paper className={classes.PaperContent} elevation={0}>
        <Toolbar className={classes.Toolbar}>
          <Input
            label="Search"
            className={classes.SearchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item, index) => (
              <TableRow key={"user-" + item.id} hover={true}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{`${item.firstName} ${item.lastName ? item.lastName : ""}`}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.phone}</TableCell>
                <TableCell>
                  {item.addresses.length > 0 ? (
                    item.addresses.map((address) => <p>{address}</p>)
                  ) : (
                    <p>No address available</p>
                  )}
                </TableCell>
                <TableCell>
                  {item.roles.map((role) => (
                    <div key={role + "-div"}>{role}</div>
                  ))}
                </TableCell>
                <TableCell>
                  <ActionButton
                    color="primary"
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    <Edit fontSize="small" />
                  </ActionButton>
                  <ActionButton
                    color="secondary"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this item?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          handleDelete(item.id);
                        },
                      });
                    }}
                  >
                    <DeleteForever fontSize="small" />
                  </ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
      <Popup
        title="Edit User Roles"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <UserForm user={userToEdit} onUpdateUser={onUpdateUser} />
      </Popup>
      <Notification
        notification={notification}
        setNotification={setNotification}
      />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {loading && <Loading />}
    </div>
  );
};

export default ListUsers;
