import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Login from "../../components/Auth/Login/Login";
import Register from "../../components/Auth/Register/Register";

const Auth = (props) => {
  return (
    <Switch>
      <Route path={props.match.url + "/Login"} component={Login} />
      <Route path={props.match.url + "/Register"} component={Register} />
      <Route exact path={props.match.url}>
        <Redirect to={props.match.url + "/Login"} />
      </Route>
    </Switch>
  );
};

export default Auth;
