import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { makeStyles } from "@material-ui/core";

import classes from "../MyAccount.module.css";
import { AuthContext } from "../../../../context/AuthContext";
import { ErrorContext } from "../../../../context/ErrorContext";
import { API } from "../../../../configs/api";
import Button from "../../../UI/Button/Button";
import { MuiButton } from "../../../Controls";
import { Vendor_Role } from "../../../../configs/constants";
import defaultImage from "../../../../assets/images/imgplaceholder.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  cover: {
    width: "100%",
    height: "30rem",
    "& img": {
      height: "100%",
      width: "auto",
      maxWidth: "100%",
    },
  },
}));

const defaultData = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  roles: [],
  profilePicture: "",
  profilePictureSrc: defaultImage,
};

const EditInfo = (props) => {
  const userId = useContext(AuthContext).userData.id;
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState(defaultData);
  const isVendor = data.roles.includes(Vendor_Role);
  const [updateError, setUpdateError] = useState("");
  const [showErrors, setShowErrors] = useState(classes.Hidden);
  const setUser = useContext(AuthContext).authenticationHandler;
  const viewError = useContext(ErrorContext).activateError;
  const muiClasses = useStyles();

  useEffect(() => {
    Axios.get(API.users.getUser + userId)
      .then((response) => {
        setData({
          ...response.data,
          profilePictureSrc: response.data.profilePicture
            ? response.data.profilePicture
            : defaultData.profilePictureSrc,
        });
        setLoaded(true);
      })
      .catch((error) => {
        viewError(true);
      });
  }, [userId, viewError]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });
  };

  const showPreview = (e) => {
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (file) => {
        if (reader.readyState === 2) {
          setData({
            ...data,
            profilePicture: imageFile,
            profilePictureSrc: file.target.result,
          });
        }
      };
      reader.readAsDataURL(imageFile);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("id", data.id);
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("phone", data.phone);
    formData.append("PhotoFile", data.profilePicture);

    Axios.put(API.users.update + data.id, formData)
      .then((response) => {
        
        if (!response.data.isAuthenticated) {
          setUpdateError(response.data.message);
          setShowErrors(classes.Visible);
        } else {
          setUser(response.data);
          const currentPath = props.location.pathname;
          const parentPath = currentPath.substring(
            0,
            currentPath.lastIndexOf("/")
          );
          props.history.push(parentPath);
        }
      })
      .catch((error) => {
        viewError(true);
      });
  };

  const userEdit = (
    <>
      <div className="row form-group">
        <div className="col">
          <label htmlFor="firstName">
            <strong>First Name*</strong>
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            className="form-control"
            placeholder="First Name"
            required
            value={data.firstName}
            onChange={handleChange}
          />
        </div>
        <div className="col">
          <label htmlFor="lastName">
            <strong>Last Name</strong>
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            className="form-control"
            placeholder="Last Name"
            required
            value={data.lastName}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="row form-group">
        <div className="col">
          <label htmlFor="phone">
            <strong>Phone</strong>
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            className="form-control"
            required
            value={data.phone}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );

  const vendorEdit = (
    <>
      <div className="row form-group">
        <div className="col-12 col-md-6">
          <div className="row form-group">
            <div className="col">
              <label htmlFor="firstName">
                <strong>Name*</strong>
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                className="form-control"
                placeholder="Name"
                required
                value={data.firstName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col">
              <label htmlFor="phone">
                <strong>Phone*</strong>
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                className="form-control"
                required
                value={data.phone}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="row form-group">
            <div className="col">
              <label>
                <strong>Profile Picture</strong>
              </label>
              <div className="card text-center">
                <div className={muiClasses.cover}>
                  <img
                    src={data.profilePictureSrc}
                    className="card-img-top"
                    alt="featured"
                  />
                </div>
                <div className="card-body">
                  <div className={muiClasses.root}>
                    <input
                      type="file"
                      id="contained-button-file"
                      name="image"
                      accept=".jpg, .jpeg, .png"
                      className={muiClasses.input}
                      onChange={showPreview}
                    />
                    <label htmlFor="contained-button-file">
                      <MuiButton
                        variant="contained"
                        color="primary"
                        component="span"
                        text="Choose Image"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className={classes.MyAccount}>
      <div className="mb-5">
        <h2>Update Information</h2>
      </div>
      <div className="inputsContainer row">
        <div className={[showErrors, "col-12 col-md-7 mb-3"].join(" ")}>
          <div className={classes.Errors}>
            <p className="mb-0">{updateError}</p>
          </div>
        </div>
        <div className="col-12">
          <form onSubmit={handleSubmit}>
            {loaded && (isVendor ? vendorEdit : userEdit)}
            <div className="row pt-5">
              <div className="col text-center">
                <Button type="submit">
                  <strong>Update Account</strong>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditInfo;
