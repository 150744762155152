import React, { useState, useEffect, useCallback } from "react";

import classes from "./HeaderSlider.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import SlickVideo from '../../assets/images/slidervideo.mp4';

/*const importFeaturedImages = (r) => {
  // r short for request
  return r.keys().map(r);
};
const featuredImages = importFeaturedImages(
  // https://picsum.photos/1350/500.jpg
  // https://picsum.photos/736/1000.jpg
  require.context("../../assets/images/featured/", false, /\.(png|jpe?g|svg)$/)
);*/

const HeaderSlider = (props) => {
  const { featuredImages } = props;
  const [currentImgIndex, setCurrentImgIndex] = useState(0);
  const imgsClass = "slider-images";
  const imageTime = 3000;
  // const slidingTime = 1000;
  // const slidingInterval = featuredImages.length * (imageTime + slidingTime);

  const updateCurrentImage = useCallback(
    (direction) => {
      setCurrentImgIndex((c) => {
        if (c + direction >= featuredImages.length) {
          return 0;
        }
        if (c + direction < 0) {
          return featuredImages.length - 1;
        }
        return c + direction;
      });
    },
    [featuredImages.length]
  );

  useEffect(() => {
    if (featuredImages.length > 0) {
      // get all slider images
      const sliderImages = document.getElementsByClassName(imgsClass);
      // clear all opacities except the current one
      for (let i = 0; i < sliderImages.length; i++) {
        const img = sliderImages[i];
        img.style.opacity = 0;
      }
      // set the current one
      sliderImages[currentImgIndex].style.opacity = 1;
    }
    // start a new timer for auto sliding
    const autoSlideInterval = setTimeout(() => {
      updateCurrentImage(1);
    }, imageTime);
    return () => clearTimeout(autoSlideInterval);
  }, [currentImgIndex, featuredImages, updateCurrentImage]);

  const toLeftHandler = (direction) => {
    updateCurrentImage(direction);
  };

  const toRightHandler = (direction) => {
    updateCurrentImage(direction);
  };

  return (
    <div className={classes.HeaderSlider}>
      <div className={classes.Slider}>
      <video width="100%" controls={false} loop autoPlay={true} muted playsInline >
        <source className={imgsClass} src={SlickVideo} type="video/mp4"/>
      </video>
        
      </div>
      {/* <div
        className={[classes.Arrow, classes.Next].join(" ")}
        onClick={() => toRightHandler(1)}
      >
        <FontAwesomeIcon icon={faAngleRight} className={classes.Icon} />
      </div>
      <div
        className={[classes.Arrow, classes.Prev].join(" ")}
        onClick={() => toLeftHandler(-1)}
      >
        <FontAwesomeIcon icon={faAngleLeft} className={classes.Icon} />
      </div> */}
    </div>
  );
};

export default React.memo(HeaderSlider);
