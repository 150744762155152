import React, { useState, useEffect, useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Axios from "axios";
import { Paper, Grid } from "@material-ui/core";

import VendorProfile from "./VendorProfile/VendorProfile";
import ListProducts from "./ListProducts/ListProducts";
import ProductForm from "./ProductForm/ProductForm";

import classes from "./MyVendor.module.css";
import { API } from "../../configs/api";
import { AuthContext } from "../../context/AuthContext";
import { ErrorContext } from "../../context/ErrorContext";
import Notification from "../Templates/Notification";
import defaultImage from "../../assets/images/imgplaceholder.jpg";

const MyVendor = (props) => {
  const vendorId = useContext(AuthContext).userData.id;
  const [vendor, setVendor] = useState(useContext(AuthContext).userData);
  const [loaded, setLoaded] = useState(false);
  const viewError = useContext(ErrorContext).activateError;
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    if (vendorId) {
      Axios.get(API.users.getUser + vendorId)
        .then((response) => {
          setVendor({
            ...response.data,
            profilePictureSrc: response.data.profilePicture
              ? response.data.profilePicture
              : defaultImage,
          });
          setLoaded(true);
        })
        .catch((error) => {
          viewError(true);
        });
    }
  }, [vendorId, viewError]);

  return (
    <Paper className={"py-5"}>
      <div className="container-fluid">
        {loaded && (
          <Grid container spacing={1}>
            {/* vendor info */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper elevation={2} className={classes.ProfileWrapper}>
                <VendorProfile vendor={vendor} />
              </Paper>
            </Grid>
            {/* job details */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper elevation={2} className={classes.ProfileWrapper}>
                <Switch>
                  <Route
                    path={`${props.match.url}/products/new`}
                    render={(props) => (
                      <ProductForm
                        vendorId={vendorId}
                        notification={notification}
                        setNotification={setNotification}
                      />
                    )}
                  />
                  <Route
                    path={`${props.match.url}/products/:id`}
                    render={(props) => (
                      <ProductForm
                        vendorId={vendorId}
                        notification={notification}
                        setNotification={setNotification}
                      />
                    )}
                  />
                  <Route
                    path={`${props.match.url}/products`}
                    render={(props) => <ListProducts vendorId={vendorId} />}
                  />
                  <Route exact path="/myvendor">
                    <Redirect to={`${props.match.url}/products`} />
                  </Route>
                </Switch>
              </Paper>
            </Grid>
          </Grid>
        )}
      </div>
      <Notification
        notification={notification}
        setNotification={setNotification}
      />
    </Paper>
  );
};

export default MyVendor;
