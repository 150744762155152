import React from "react";

import classes from "./NavItems.module.css";
import NavItem from "./NavItem/NavItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const NavItems = (props) => {
  return (
    <nav className={classes.NavItems}>
      <div className={classes.Back} onClick={props.closeSideDrawer}>
        <h3>
          Back
          <span className={classes.Arrow}>
            <FontAwesomeIcon icon={faAngleRight} />
          </span>
        </h3>
      </div>

      {props.categories.map((category) => (
        <NavItem key={"category-" + category.id} category={category} />
      ))}
    </nav>
  );
};

export default NavItems;
