import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { Paper, Grid, makeStyles } from "@material-ui/core";

import AreaBar from "../../components/AreaBar/AreaBar";
import VendorProfile from "../../components/MyVendor/VendorProfile/VendorProfile";
import ListProducts from "../../components/ListProducts/ListProducts";

import { API } from "../../configs/api";
import { ErrorContext } from "../../context/ErrorContext";
import defaultImage from "../../assets/images/imgplaceholder.jpg";
import { APPROVED_PRODUCTS } from "../../configs/constants";

const useStyles = makeStyles({
  fullHeight: {
    height: "100%",
  },
});

const Vendor = (props) => {
  const classes = useStyles();
  const { vendorId } = props.match.params;
  const [vendor, setVendor] = useState();
  const [products, setProducts] = useState([]);
  const [approvedProducts, setApprovedProducts] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const viewError = useContext(ErrorContext).activateError;

  useEffect(() => {
    if (vendorId) {
      Axios.get(API.users.getUser + vendorId)
        .then((response) => {
          setVendor({
            ...response.data,
            profilePictureSrc: response.data.profilePicture
              ? response.data.profilePicture
              : defaultImage,
          });
          setLoaded(true);
        })
        .catch((error) => {
          viewError(true);
        });

      Axios.get(API.products.getByVendroId + vendorId)
        .then((response) => {
          
          setProducts(response.data);
        })
        .catch((error) => {
          viewError(true);
        });
    }
  }, [vendorId, viewError]);

  useEffect(() => {
    const approvedProducts = products.filter(
      (product) => product.status.name === APPROVED_PRODUCTS
    );
    setApprovedProducts(approvedProducts);
  }, [products]);

  return (
    <>
      <AreaBar title={loaded ? vendor.firstName : "Vendor"} area="Products" />

      <Paper className={"p-5"}>
        {loaded && (
          <Grid container spacing={5} className="p-5">
            {/* vendor info */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper elevation={2} className={classes.fullHeight}>
                <VendorProfile vendor={vendor} />
              </Paper>
            </Grid>
            {/* job details */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper elevation={2} className={classes.fullHeight}>
                <Grid container className="p-5">
                  {approvedProducts.length > 0 ? (
                    <ListProducts products={approvedProducts} />
                  ) : (
                    <div className="container">
                      <div className="row">
                        <div className="col">
                          <h3 className={"text-center p-5"}>
                            No Items to view yet, come back soon
                          </h3>
                        </div>
                      </div>
                    </div>
                  )}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Paper>
    </>
  );
};

export default Vendor;
