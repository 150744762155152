import React from "react";

import classes from "./SideDrawer.module.css";
import Backdrop from "../../UI/Backdrop/Backdrop";
import NavItems from "../NavItems/NavItems";

const SideDrawer = (props) => {
  const currentState = props.isOpen ? classes.Open : classes.Close;

  return (
    <React.Fragment>
      <Backdrop show={props.isOpen} close={props.close} />
      <nav className={[classes.SideDrawer, currentState].join(" ")}>
        <NavItems closeSideDrawer={props.close} categories={props.categories} />
      </nav>
    </React.Fragment>
  );
};

export default SideDrawer;
