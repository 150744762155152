import React from "react";

import classes from "./UserSidePanel.module.css";
import PanelLink from "./PanelLink/PanelLink";

const UserSidePanel = () => {
  return (
    <div className={classes.UserSidePanel}>
      <ul className={classes.Links}>
        <li>
          <PanelLink link="/myaccount" exact>
            Account Info
          </PanelLink>
        </li>
        <li>
          <PanelLink link="/myaccount/myorders">My Orders</PanelLink>
        </li>
        <li>
          <PanelLink link="/myaccount/wishlist">wishlist</PanelLink>
        </li>
        {/* <li>
          <PanelLink link="/myaccount/whatever">Log Out</PanelLink>
        </li> */}
      </ul>
    </div>
  );
};

export default UserSidePanel;
