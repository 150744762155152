import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";

import classes from "./SideCart.module.css";
import Backdrop from "../../UI/Backdrop/Backdrop";
import { API } from "../../../configs/api";
import { CartContext } from "../../../context/CartContext";
import SideCartProduct from "./SideCartProduct/SideCartProduct";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const SideCart = (props) => {
  const [cartProducts, setCartProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const cart = useContext(CartContext).cart;
  const currentState = props.isOpen ? classes.Open : classes.Close;

  useEffect(() => {
    if (cart.length > 0) {
      const formData = new FormData();
      cart.forEach((product, index) =>
        formData.append(`ids[${index}]`, product.productId)
      );
      Axios.post(API.products.getProductsByIds, formData)
        .then((response) => {
          const finalProducts = [];
          const products = response.data;
          cart.forEach((cp) => {
            // get the actual product in response
            const product = products.find((p) => p.id === cp.productId);
            if (product) {
              product.count = cp.count;
              finalProducts.push({ ...product });
            }
          });
          setCartProducts(finalProducts);
        })
        .catch((error) => {});
    } else {
      setCartProducts([]);
      setTotalPrice(0);
    }
  }, [cart]);

  useEffect(() => {
    if (cartProducts.length > 0) {
      let total = 0;
      cartProducts.forEach((product) => {
        let price = product.hasOffer
          ? product.price - product.offer
          : product.price;
        price = price * product.count;
        total += price;
      });
      setTotalPrice(total);
    }
  }, [cartProducts]);

  return (
    <React.Fragment>
      <Backdrop show={props.isOpen} close={props.close} />
      <div className={[classes.SideCart, currentState].join(" ")}>
        <div className={classes.Back} onClick={props.close}>
          <h3 className="mb-0">
            Back
            <span className={classes.Arrow}>
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </h3>
        </div>

        <div className={classes.CartContent}>
          <ul className={[classes.Products, "mb-0"].join(" ")}>
            {cartProducts.map((cp) => (
              <li key={"cartProduct-" + cp.id}>
                <SideCartProduct product={cp} />
              </li>
            ))}
          </ul>
          <ul className={[classes.Actions, "mb-0"].join(" ")}>
            <li className="d-flex justify-content-between">
              <h3 className="text-capitalize mb-0" style={{ color: "#333" }}>
                Total:
              </h3>
              <h3 className="text-uppercase mb-0" style={{ color: "#999" }}>
                {totalPrice + " EGP"}
              </h3>
            </li>
            <li>
              <div className={classes.Buttons}>
                <Link to="/cart" className="btn" onClick={props.close}>
                  View Cart
                </Link>
                <Link
                  to="/checkout"
                  className={["btn", classes.Checkout].join(" ")}
                  onClick={props.close}
                >
                  CHECKOUT
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(SideCart);
