import React, { useState, useEffect, useContext, useCallback } from "react";
import Axios from "axios";
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
} from "@material-ui/core";

import classes from "../../Admin.module.css";
import { API } from "../../../../configs/api";
import { ErrorContext } from "../../../../context/ErrorContext";
import FeaturedImageForm from "./FeaturedImagesForm";
import Popup from "../../../Templates/Popup";
import useTable from "../../../Templates/useTable";
import Notification from "../../../Templates/Notification";
import ConfirmDialog from "../../../Templates/ConfirmDialog";
import { Input, MuiButton, ActionButton } from "../../../Controls";
import { Search, Add, DeleteForever } from "@material-ui/icons";

const headCells = [
  { id: "#", label: "#", disableSorting: true },
  { id: "id", label: "ID", disableSorting: true },
  { id: "name", label: "Image", disableSorting: true },
  { id: "actions", label: "", disableSorting: true },
];

const FeaturedImages = (props) => {
  const [images, setImages] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const viewError = useContext(ErrorContext).activateError;

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    setPage,
  } = useTable(images, headCells, filterFn);

  const updateImages = useCallback(() => {
    Axios.get(API.featuredImages.route)
      .then((response) => {
        setImages(response.data);
      })
      .catch((error) => {
        viewError(true);
      });
  }, [viewError]);

  useEffect(() => {
    updateImages();
  }, [updateImages]);

  /*const openInPopup = (item) => {
    setOpenPopup(true);
    // we can use this if we wanna set an item for edit
    // before we actually open the popup form
  };*/

  const handleSearch = (e) => {
    let value = e.target.value.toLowerCase();
    setFilterFn({
      fn: (items) => {
        if (value === "") {
          return items;
        } else {
          return items.filter((item) =>
            String(item.id).toLowerCase().includes(value)
          );
        }
      },
    });
    setPage(0);
  };

  const handleDelete = (id) => {
    Axios.delete(API.featuredImages.route + id)
      .then((response) => {
        const newImages = [...images];
        const deletedIndex = images.findIndex((image) => image.id === id);
        newImages.splice(deletedIndex, 1);
        setImages(newImages);

        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setNotification({
          isOpen: true,
          message: "Deleted Successfully",
          type: "error",
        });
      })
      .catch((error) => {
        viewError(true);
      });
  };

  const onAddImage = () => {
    setOpenPopup(false);
    setNotification({
      isOpen: true,
      message: "Added Successfully",
      type: "success",
    });
    updateImages();
  };

  return (
    <div className={classes.AdminList}>
      <Paper className={classes.PaperContent} elevation={0}>
        <Toolbar className={classes.Toolbar}>
          <Input
            label="Search"
            className={classes.SearchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <MuiButton
            text="Add New"
            variant="outlined"
            startIcon={<Add />}
            onClick={() => {
              setOpenPopup(true);
            }}
          />
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item, index) => (
              <TableRow key={"featuredImage-" + item.id} hover={true}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.id}</TableCell>
                <TableCell>
                  <img
                    src={item.name}
                    alt="Featured"
                    className={classes.ItemImage}
                  />
                </TableCell>
                <TableCell>
                  <ActionButton
                    color="secondary"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this item?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          handleDelete(item.id);
                        },
                      });
                    }}
                  >
                    <DeleteForever fontSize="small" />
                  </ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
      <Popup
        title="Add New Featured Image"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <FeaturedImageForm onAddImage={onAddImage} />
      </Popup>
      <Notification
        notification={notification}
        setNotification={setNotification}
      />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
};

export default FeaturedImages;
