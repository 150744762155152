import React from "react";
import { Link } from "react-router-dom";

import classes from "../OrderCard.module.css";
import { getFullDate } from "../../../../../configs/utils";
import { DELIVERY_MESSAGE, PAYMENT_EWALETMETHOD_MESSAGE, PAYMENT_EWALETMETHOD1, PAYMENT_EWALETMETHOD2, PAYMENT_SEND_WHATSMESSAGE ,FREEORDER_MESSAGE} from "../../../../../configs/constants";

const DetailsSmall = (props) => {
  const { order, openInPopup } = props;
  let dateOrdered = getFullDate(order.dateOrdered);

  return (
    <div>
      <div className={classes.OrderDetails}>
        <div className={classes.Detail}>
          <h5>Contact Name</h5>
          <p>{order.contactName}</p>
        </div>
        <div className={classes.Detail}>
          <h5>Order Placed</h5>
          <p>{dateOrdered}</p>
        </div>
        <div className={classes.Detail}>
          <h5>Order Status</h5>
          <p>{order.orderStatus}</p>
        </div>
        <div className={classes.Detail}>
          <h5>Total</h5>
          <p>{order.subtotal} EGP</p>
        </div>
        <div className={classes.Detail}>
          <h5>Order #: {order.id}</h5>
          <p>
            <Link to="#" onClick={() => openInPopup(order)}>
              Order Details
            </Link>
          </p>
        </div>
      </div>
      {order.subtotal > 0 && order.orderStatus == "Pending"?
      <div style={{direction:"rtl"}}>
        <h4 className="text-uppercase mb-0 text-center" style={{ color: "#cf0909" }}>
          {PAYMENT_EWALETMETHOD_MESSAGE}
        </h4>
        <h3 className="text-uppercase mb-0 text-center" style={{ color: "#5bacd7" }}>
          {PAYMENT_EWALETMETHOD1}
        </h3>
        <h3 className="text-uppercase mb-0 text-center" style={{ color: "#5bacd7" }}>
          {PAYMENT_EWALETMETHOD2}
        </h3>
        <h4 className="text-uppercase mb-0 text-center" style={{ color: "#cf0909" }}>
          {PAYMENT_SEND_WHATSMESSAGE}
        </h4>
      </div>
      : <span></span>}
    </div>
  );
};

export default DetailsSmall;
