import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";

import classes from "./SideCartProduct.module.css";
import { API } from "../../../../configs/api";
import { AuthContext } from "../../../../context/AuthContext";
import { CartContext } from "../../../../context/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const SideCartProduct = (props) => {
  const userId = useContext(AuthContext).userData.id;
  const removeFromCart = useContext(CartContext).removeCartProduct;

  const finalPrice = props.product.hasOffer
    ? props.product.price - props.product.offer
    : props.product.price;

  const deleteHandler = () => {
    if (userId) {
      Axios.delete(
        `${API.users.removeFromCart}${userId}/${props.product.id}`
      )
        .then((response) => {})
        .catch((error) => {});
    }
    removeFromCart(props.product.id);
  };

  return (
    <div className={["row m-0", classes.SideCartProduct].join(" ")}>
      <div className="col-4">
        <Link to={"/product/" + props.product.id}>
          <img
            src={props.product.photos[0].name}
            alt={props.product.name}
            className="img-fluid"
          />
        </Link>
      </div>
      <div className="col-6">
        <Link
          to={"/product/" + props.product.id}
          className={classes.ProductName}
        >
          <h3 className="mb-3">{props.product.name}</h3>
        </Link>
        <h4>
          {props.product.count} X {finalPrice}
        </h4>
      </div>
      <div className={["col-2 text-center", classes.Trash].join(" ")}>
        <FontAwesomeIcon icon={faTrashAlt} onClick={deleteHandler} />
      </div>
    </div>
  );
};

export default React.memo(SideCartProduct);
