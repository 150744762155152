import React, { useState, useEffect, useContext, useRef } from "react";
import Axios from "axios";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Paper } from "@material-ui/core";

import classes from "../Auth.module.css";
import { API } from "../../../configs/api";
import { ErrorContext } from "../../../context/ErrorContext";
import { AuthContext } from "../../../context/AuthContext";
import AreaBar from "../../AreaBar/AreaBar";
import Button from "../../UI/Button/Button";
import { MuiButton } from "../../Controls";
import Loading from "../../UI/Loading/Loading";
import defaultImage from "../../../assets/images/imgplaceholder.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  cover: {
    width: "100%",
    height: "30rem",
    "& img": {
      height: "100%",
      width: "auto",
      maxWidth: "100%",
    },
  },
}));

const roles = [
  { id: "User", name: "Customer" },
  { id: "Vendor", name: "Vendor" },
];
const defaultData = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
  role: roles[0].id,
  profilePicture: "",
  profilePictureSrc: defaultImage,
  addressDetails: "",
  nearestLandmark: "",
  cityId: "",
};

const Register = (props) => {
  const [data, setData] = useState(defaultData);
  const [cities, setCities] = useState([]);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [registerError, setRegisterError] = useState("");
  const [showErrors, setShowErrors] = useState(classes.Hidden);
  const setUser = useContext(AuthContext).authenticationHandler;
  const viewError = useContext(ErrorContext).activateError;
  const [loading, setLoading] = useState(false);
  const confirmPassword = useRef();
  const muiClasses = useStyles();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      Axios.get(API.addresses.cities)
        .then((response) => {
          setCities(response.data);
        })
        .catch((error) => {
          viewError(true);
        });
    } else {
      props.history.replace("/");
    }
  }, [props.history, viewError]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });

    if (name === "confirmPassword") {
      if (value !== data.password) {
        confirmPassword.current.setCustomValidity("Passwords Don't Match");
      } else {
        confirmPassword.current.setCustomValidity("");
      }
    }
  };

  const showPreview = (e) => {
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (file) => {
        if (reader.readyState === 2) {
          setData({
            ...data,
            profilePicture: imageFile,
            profilePictureSrc: file.target.result,
          });
        }
      };
      reader.readAsDataURL(imageFile);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("password", data.password);
    formData.append("role", data.role);
    formData.append("photoFile", data.profilePicture);
    formData.append("addressDetails", data.addressDetails);
    formData.append("nearestLandmark", data.nearestLandmark);
    formData.append("cityId", data.cityId ? data.cityId : 0);

    Axios.post(API.users.register, formData)
      .then((response) => {
        setLoading(false);
        if (!response.data.isAuthenticated) {
          setRegisterError(response.data.message);
          setShowErrors(classes.Visible);
        } else {
          setUser(response.data);
          props.history.push("/");
        }
      })
      .catch((error) => {
        setLoading(false);
        viewError(true);
      });
  };

  const userRegistration = (
    <>
      <div className="row form-group">
        <div className="col-12 col-md-6">
          <label htmlFor="firstName">
            <strong>First Name*</strong>
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            className="form-control"
            placeholder="First Name"
            required
            value={data.firstName}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6">
          <label htmlFor="lastName">
            <strong>Last Name*</strong>
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            className="form-control"
            placeholder="Last Name"
            required
            value={data.lastName}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="row form-group">
        <div className="col-12 col-md-6">
          <label htmlFor="email">
            <strong>Email*</strong>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="form-control"
            placeholder="example@example.com"
            required
            value={data.email}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6">
          <label htmlFor="phone">
            <strong>Phone*</strong>
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            className="form-control"
            required
            value={data.phone}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="row form-group">
        <div className="col-12 col-md-6">
          <label htmlFor="password">
            <strong>Password*</strong>
          </label>
          <input
            type="password"
            id="password"
            name="password"
            className="form-control"
            placeholder="Password"
            minLength="8"
            required
            value={data.password}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6">
          <label htmlFor="confirmPassword">
            <strong>Confirm Password*</strong>
          </label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            className="form-control"
            placeholder="Confirm Password"
            required
            value={data.confirmPassword}
            onChange={handleChange}
            ref={confirmPassword}
          />
        </div>
      </div>

      <div>
        <div className="row mt-5">
          <div className="col">
            <h2>Address</h2>
            <hr />
          </div>
        </div>
        <div className="row form-group">
          <div className="col">
            <label htmlFor="addressDetails">
              <strong>Address Details*</strong>
            </label>
            <input
              type="text"
              id="addressDetails"
              name="addressDetails"
              className="form-control"
              placeholder="Address Details"
              required
              value={data.addressDetails}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-12 col-md-6">
            <label htmlFor="nearestLandmark">
              <strong>Nearest Landmark*</strong>
            </label>
            <input
              type="text"
              id="nearestLandmark"
              name="nearestLandmark"
              className="form-control"
              placeholder="Nearest Landmark"
              required
              value={data.nearestLandmark}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6">
            <label htmlFor="cityId">
              <strong>City</strong>
            </label>
            <select
              id="cityId"
              name="cityId"
              className="form-control"
              required
              value={data.cityId}
              onChange={handleChange}
            >
              <option value="" disabled>
                Choose...
              </option>
              {cities.map((city) => (
                <option key={"city-" + city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );

  const vendorRegistration = (
    <>
      <div className="row form-group">
        <div className="col-12 col-md-6">
          <div className="row form-group">
            <div className="col">
              <label htmlFor="firstName">
                <strong>Name*</strong>
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                className="form-control"
                placeholder="Name"
                required
                value={data.firstName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col">
              <label htmlFor="email">
                <strong>Email*</strong>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="form-control"
                placeholder="example@example.com"
                required
                value={data.email}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col">
              <label htmlFor="phone">
                <strong>Phone*</strong>
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                className="form-control"
                required
                value={data.phone}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col">
              <label htmlFor="password">
                <strong>Password*</strong>
              </label>
              <input
                type="password"
                id="password"
                name="password"
                className="form-control"
                placeholder="Password"
                minLength="8"
                required
                value={data.password}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col">
              <label htmlFor="confirmPassword">
                <strong>Confirm Password*</strong>
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                className="form-control"
                placeholder="Confirm Password"
                required
                value={data.confirmPassword}
                onChange={handleChange}
                ref={confirmPassword}
              />
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="row form-group">
            <div className="col">
              <label>
                <strong>Profile Picture</strong>
              </label>
              <div className="card text-center">
                <div className={muiClasses.cover}>
                  <img
                    src={data.profilePictureSrc}
                    className="card-img-top"
                    alt="featured"
                  />
                </div>
                <div className="card-body">
                  <div className={muiClasses.root}>
                    <input
                      type="file"
                      id="contained-button-file"
                      name="image"
                      accept=".jpg, .jpeg, .png"
                      className={muiClasses.input}
                      onChange={showPreview}
                    />
                    <label htmlFor="contained-button-file">
                      <MuiButton
                        variant="contained"
                        color="primary"
                        component="span"
                        text="Choose Image"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <React.Fragment>
      <AreaBar title="Create Account" area="Registeration" />

      <Paper className="p-5">
        <div className={classes.Auth}>
          <div className="container p-5">
            <div className="row">
              <div className={[showErrors, "col-12 mb-3"].join(" ")}>
                <div className={classes.Errors}>
                  <p className="mb-0">{registerError}</p>
                </div>
              </div>
              <div className={[classes.Box, "col"].join(" ")}>
                <div className="row">
                  <div className="col">
                    <h2 className={classes.BoxTitle}>Create Account</h2>
                  </div>
                </div>
                <div className="inputsContainer row">
                  <div className="col">
                    <div className={classes.BoxContent}>
                      <form onSubmit={handleSubmit}>
                        <div className="row form-group">
                          <div className="col-12 col-md-6">
                            <label htmlFor="role">
                              <strong>Account Type*</strong>
                            </label>
                            <select
                              id="role"
                              name="role"
                              className="form-control"
                              required
                              value={data.role}
                              onChange={handleChange}
                            >
                              <option value="" disabled>
                                Choose...
                              </option>
                              {roles.map((role) => (
                                <option key={role.id} value={role.id}>
                                  {role.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {data.role === roles[0].id
                          ? userRegistration
                          : vendorRegistration}
                        <div className="row pt-5">
                          <div className="col">
                            <div className="form-check form-check-inline">
                              <input
                                type="checkbox"
                                id="terms"
                                name="terms"
                                className="form-check-input"
                                checked={termsAccepted}
                                onChange={() =>
                                  setTermsAccepted((prevState) => !prevState)
                                }
                                required
                              />
                              <label
                                htmlFor="terms"
                                className="form-check-label"
                              >
                                <strong>
                                  I have read and agree to the{" "}
                                  <Link to="/termsofservice">
                                    Terms of service
                                  </Link>
                                </strong>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row pt-5">
                          <div className="col">
                            <Button type="submit" disabled={!termsAccepted}>
                              <strong>Create Account</strong>
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Paper>
      {loading && <Loading />}
    </React.Fragment>
  );
};

export default Register;
