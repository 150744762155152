import React from "react";
import { Link } from "react-router-dom";

import classes from "../OrderCard.module.css";

const Products = (props) => {
  const { products } = props;

  const getProductPrice = (product) => {
    const finalPrice = product.hasOffer
      ? product.price - product.offer
      : product.price;

    return (
      <div className="d-flex align-items-center text-uppercase">
        {product.hasOffer ? (
          <h5 className={classes.DelPrice}>
            <del>{product.price + " EGP"}</del>
          </h5>
        ) : null}
        <h4 className="ml-3">{finalPrice + " EGP"}</h4>
      </div>
    );
  };

  return (
    <div className={classes.OrderProducts}>
      <div className="mt-3">
        <div className="row">
          {products.map((product) => (
            <div className="col-12 mb-3" key={"orderProduct-" + product.id}>
              <div className={classes.Item}>
                <div className={classes.ItemImg}>
                  <Link to={"/product/" + product.productId}>
                    <img
                      src={product.photo}
                      alt={product.name}
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className={classes.ItemDetails}>
                  <div className="pl-5">
                    <div className="mb-3">
                      <h3>
                        <Link to={"/product/" + product.productId}>
                          {product.name}
                        </Link>
                      </h3>
                    </div>
                    <div className="mb-3">
                      <h4>
                        {product.count}
                      </h4>
                      {product.videoDownloadLink !="" ?
                        <h4 className={classes.DownloadLink}>
                        <a href={product.videoDownloadLink} target="_blank" title={product.name}>
                          Download URL
                        </a>
                      </h4>
                      : <span className={classes.DownloadLink} title="Download link will be avilable once the order is confermed form Customer service">
                        Download link is Pending</span>}
                      
                    </div>
                    <div className="mb-3">{getProductPrice(product)}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Products;
