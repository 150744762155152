import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import Axios from "axios";
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Tabs,
  Tab,
  InputAdornment,
} from "@material-ui/core";

import classes from "../MyVendor.module.css";
import { API } from "../../../configs/api";
import { ErrorContext } from "../../../context/ErrorContext";
import useTable from "../../Templates/useTable";
import Notification from "../../Templates/Notification";
import ConfirmDialog from "../../Templates/ConfirmDialog";
import { Input, MuiButton, ActionButton } from "../../Controls";
import { Search, Add, Edit, DeleteForever } from "@material-ui/icons";

const headCells = [
  { id: "#", label: "#", disableSorting: true },
  { id: "id", label: "ID" },
  { id: "name", label: "Name" },
  { id: "categories", label: "Categories", disableSorting: true },
  { id: "subCategories", label: "Sub Categories", disableSorting: true },
  { id: "cover", label: "Cover", disableSorting: true },
  { id: "actions", label: "", disableSorting: true },
];

const ListProducts = (props) => {
  const { vendorId } = props;
  const [products, setProducts] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const [activeTabProducts, setActiveTabProducts] = useState(products);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const viewError = useContext(ErrorContext).activateError;

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    setPage,
  } = useTable(activeTabProducts, headCells, filterFn);

  useEffect(() => {
    // get vendor products
    Axios.get(API.products.getByVendroId + vendorId)
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        viewError(true);
      });

    // get product statuses
    Axios.get(API.products.getProductStatuses)
      .then((response) => {
        setStatuses(response.data);
        // setActiveTab(response.data[0].name);
      })
      .catch((error) => {
        viewError(true);
      });
  }, [vendorId, viewError]);

  useEffect(() => {
    let currentTabProducts = [];
    if (activeTab === "All") {
      currentTabProducts = products;
    } else {
      currentTabProducts = products.filter(
        (product) => product.status.name === activeTab
      );
    }
    setActiveTabProducts(currentTabProducts);
    setPage(0);
  }, [products, activeTab]);

  const handleSearch = (e) => {
    let value = e.target.value.toLowerCase();
    setFilterFn({
      fn: (items) => {
        if (value === "") {
          return items;
        } else {
          return items.filter(
            (item) =>
              String(item.id).toLowerCase().includes(value) ||
              String(item.name).toLowerCase().includes(value) ||
              // String(item.category.name).toLowerCase().includes(value) ||
              // String(item.subCategory.name).toLowerCase().includes(value) ||
              item.categories.some((category) =>
                category.name.toLowerCase().includes(value)
              ) ||
              item.subCategories.some((subCat) =>
                subCat.name.toLowerCase().includes(value)
              )
          );
        }
      },
    });
    setPage(0);
  };

  const handleDelete = (id) => {
    Axios.delete(API.products.route + id)
      .then((response) => {
        const newProducts = [...products];
        const deletedIndex = products.findIndex((prod) => prod.id === id);
        newProducts.splice(deletedIndex, 1);
        setProducts(newProducts);

        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setNotification({
          isOpen: true,
          message: "Deleted Successfully",
          type: "error",
        });
      })
      .catch((error) => {
        viewError(true);
      });
  };

  return (
    <div className={classes.VendorList}>
      <Paper className={classes.PaperContent} elevation={0}>
        <Toolbar className={classes.Toolbar}>
          <Input
            label="Search"
            className={classes.SearchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <MuiButton
            text="Add New"
            variant="outlined"
            startIcon={<Add />}
            onClick={() => {
              props.history.push(`${props.match.url}/new`);
            }}
          />
        </Toolbar>

        {/* tabs go here */}
        <Tabs
          value={activeTab}
          onChange={(event, value) => {
            setActiveTab(value);
          }}
          className="mt-3"
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab value={"All"} label={"All"} />
          {statuses.map((status) => (
            <Tab
              key={`${status.name}-${status.id}`}
              value={status.name}
              label={status.name}
            />
          ))}
        </Tabs>

        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item, index) => (
              <TableRow key={"product-" + item.id} hover={true}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  {item.categories.length > 0
                    ? item.categories.map((category) => (
                        <div key={"categoryDiv-" + category.id}>
                          {category.name}
                        </div>
                      ))
                    : "No Categories"}
                </TableCell>
                <TableCell>
                  {item.subCategories.length > 0
                    ? item.subCategories.map((subCategory) => (
                        <div key={"subCategoryDiv-" + subCategory.id}>
                          {subCategory.name}
                        </div>
                      ))
                    : "No SubCategories"}
                </TableCell>
                <TableCell>
                  <img
                    src={item.photos[0]?.name}
                    alt={item.name}
                    className={classes.ItemImage}
                  />
                </TableCell>
                <TableCell>
                  <Link to={`${props.match.url}/${item.id}`}>
                    <ActionButton color="primary">
                      <Edit fontSize="small" />
                    </ActionButton>
                  </Link>
                  <ActionButton
                    color="secondary"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this item?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          handleDelete(item.id);
                        },
                      });
                    }}
                  >
                    <DeleteForever fontSize="small" />
                  </ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>

      <Notification
        notification={notification}
        setNotification={setNotification}
      />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
};

export default withRouter(ListProducts);
