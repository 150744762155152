import React from "react";
import { Link } from "react-router-dom";

import classes from "../OrderCard.module.css";
import { getFullDate } from "../../../../../configs/utils";

const DetailsSmall = (props) => {
  const { order, openInPopup /*, setOpenForm*/ } = props;
  let dateOrdered = getFullDate(order.dateOrdered);

  return (
    <div className={classes.OrderDetails}>
      <div className={classes.Detail}>
        <h5>Contact Name</h5>
        <p>{order.contactName}</p>
      </div>
      <div className={classes.Detail}>
        <h5>Order Placed</h5>
        <p>{dateOrdered}</p>
      </div>
      <div className={classes.Detail}>
        <h5>Order Status</h5>
        <p>{order.orderStatus}</p>
      </div>
      <div className={classes.Detail}>
        <h5>Total</h5>
        <p>{order.subtotal} EGP</p>
      </div>
      <div className={classes.Detail}>
        <h5>Order #: {order.id}</h5>
        <p>
          <Link to="#" onClick={() => openInPopup(order)}>
            Order Details
          </Link>
        </p>
      </div>
    </div>
  );
};

export default DetailsSmall;
