import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";

import classes from "./CartProduct.module.css";
import { API } from "../../../../configs/api";
import { AuthContext } from "../../../../context/AuthContext";
import { CartContext } from "../../../../context/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const CartProduct = (props) => {
  const [count, setCount] = useState(props.product.count);
  const userId = useContext(AuthContext).userData.id;
  const removeFromCart = useContext(CartContext).removeCartProduct;
  const updateCartProductCount = useContext(CartContext).updateProductCount;

  const finalPrice = props.product.hasOffer
    ? props.product.price - props.product.offer
    : props.product.price;

  const deleteHandler = () => {
    if (userId) {
      Axios.delete(
        `${API.users.removeFromCart}${userId}/${props.product.id}`
      )
        .then((response) => {})
        .catch((error) => {});
    }
    removeFromCart(props.product.id);
  };

  const handleIncrease = () => {
    setCount((prevCount) => {
      if (userId) {
        const formData = new FormData();
        formData.append("count", prevCount + 1);
        Axios.put(
          `${API.users.updateCartProduct}${userId}/${props.product.id}`,
          formData
        )
          .then((response) => {})
          .catch((error) => {});
      }
      updateCartProductCount(
        props.product.id,
        prevCount + 1
      );
      return prevCount + 1;
    });
  };

  const handleDecrease = () => {
    setCount((prevCount) => {
      if (prevCount > 1) {
        if (userId) {
          const formData = new FormData();
          formData.append("count", prevCount - 1);
          Axios.put(
            `${API.users.updateCartProduct}${userId}/${props.product.id}`,
            formData
          )
            .then((response) => {})
            .catch((error) => {});
        }
        updateCartProductCount(
          props.product.id,
          prevCount - 1
        );
        return prevCount - 1;
      }
      return prevCount;
    });
  };

  return (
    <div className={["row", classes.CartProduct].join(" ")}>
      <div className="col-4 col-md-2 col-lg-1">
        <Link to={"/product/" + props.product.id}>
          <img
            src={props.product.photos[0].name}
            alt={props.product.name}
            className="img-fluid"
          />
        </Link>
      </div>
      <div className="col-8 col-md-10 col-lg-11">
        <div className="row align-items-center">
          <div className="col-12 col-md-3 d-flex flex-column align-items-md-center">
            <Link to={"/product/" + props.product.id} className="d-inline-block">
              <h3>{props.product.name}</h3>
            </Link>
          </div>
          <div className="col-12 col-md-2">
            <h2 className={classes.Price}>{finalPrice + " EGP"}</h2>
          </div>
          <div className="col-12 col-md-3 mb-3">
            <div className="d-flex">
              <button
                className={["btn", classes.MdText].join(" ")}
                onClick={handleDecrease}
              >
                -
              </button>
              <input
                type="number"
                name="count"
                id="count"
                className={[
                  "form-control text-center",
                  classes.InputCounter,
                ].join(" ")}
                value={count}
                disabled
                placeholder="Count"
              />
              <button
                className={["btn", classes.MdText].join(" ")}
                onClick={handleIncrease}
              >
                +
              </button>
            </div>
          </div>
          <div className={["col-12 col-md-1 mb-3", classes.Trash].join(" ")}>
            <FontAwesomeIcon icon={faTrashAlt} onClick={deleteHandler} />
          </div>
          <div className="col-12 col-md-3 text-md-right">
            <h2 className={classes.Price}>
              {"Total: " + finalPrice * props.product.count + " EGP"}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CartProduct);
