import React from "react";
import { Paper } from "@material-ui/core";

import AreaBar from "../../components/AreaBar/AreaBar";
import ListCategories from "../../components/ListCategories/ListCategories";

const Categories = (props) => {
  return (
    <React.Fragment>
      <AreaBar title="Categories" area="Categories" />

      <Paper className="py-5">
        <div className="container">
          <ListCategories categories={props.categories} />
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default Categories;
