import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { Paper } from "@material-ui/core";

import classes from "./Checkout.module.css";
import { API } from "../../configs/api";
import { AuthContext } from "../../context/AuthContext";
import { CartContext } from "../../context/CartContext";
import { ErrorContext } from "../../context/ErrorContext";
import AreaBar from "../AreaBar/AreaBar";
import Loading from "../UI/Loading/Loading";
import MiniProduct from "./MiniCartProduct/MiniProduct";
import ThanksMessage from "./ThanksMessage/ThanksMessage";
import { DELIVERY_MESSAGE, PAYMENT_EWALETMETHOD_MESSAGE, PAYMENT_EWALETMETHOD1, PAYMENT_EWALETMETHOD2, PAYMENT_SEND_WHATSMESSAGE ,FREEORDER_MESSAGE} from "../../configs/constants";

const defaultData = {
  contactName: "",
  contactEmail: "",
  contactPhone: "",
  addressDetails: "",
  nearestLandmark: "",
  cityId: "",
  addressId: "",
};

const Checkout = (props) => {
  const [data, setData] = useState(defaultData);
  const [cities, setCities] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [cartProducts, setCartProducts] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [shipping, setShipping] = useState(0);
  const cart = useContext(CartContext).cart;
  const clearCart = useContext(CartContext).clearCart;
  const isAuthenticated = useContext(AuthContext).isAuthenticated;
  const userId = useContext(AuthContext).userData.id;
  const viewError = useContext(ErrorContext).activateError;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Axios.get(API.addresses.cities)
      .then((response) => {
        setCities(response.data);
      })
      .catch((error) => {
        viewError(true);
      });
  }, [viewError]);

  useEffect(() => {
    if (userId) {
      Axios.get(API.addresses.getUserAddresses + userId)
        .then((response) => {
          setAddresses(response.data);
          setData((prevData) => {
            return {
              ...prevData,
              addressId: response.data.length > 0 ? response.data[0].id : 0,
            };
          });
          setShipping(0);
          //setShipping(+response.data[0].city.shipping);
        })
        .catch((error) => {
          viewError(true);
        });
    }
  }, [userId, viewError]);

  useEffect(() => {
    if (cart.length > 0) {
      const formData = new FormData();
      cart.forEach((product, index) =>
        formData.append(`ids[${index}]`, product.productId)
      );
      Axios.post(API.products.getProductsByIds, formData)
        .then((response) => {
          const finalProducts = [];
          const products = response.data;
          cart.forEach((cp) => {
            // get the actual product in response
            const product = products.find((p) => p.id === cp.productId);
            if (product) {
              product.count = cp.count;
              finalProducts.push({ ...product });
            }
          });
          setCartProducts(finalProducts);
        })
        .catch((error) => {
          viewError(true);
        });
    } else {
      setCartProducts([]);
      setSubtotal(0);
    }
  }, [cart, viewError]);

  useEffect(() => {
    if (cartProducts.length > 0) {
      let total = 0;
      cartProducts.forEach((product) => {
        let price = product.hasOffer
          ? product.price - product.offer
          : product.price;
        price = price * product.count;
        total += price;
      });
      setSubtotal(total);
    }
  }, [cartProducts]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });

    if (name === "addressId" || name === "cityId") {
      let cityId = value;
      if (isAuthenticated) {
        cityId = addresses.find((address) => address.id === +value).cityId;
      }
      const shippingFees = +cities.find((city) => city.id === +cityId).shipping;
      setShipping(shippingFees);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    if (isAuthenticated) {
      if (userId) {
        formData.append("userId", userId);
        formData.append("addressId", data.addressId);
      } else {
        viewError(true);
      }
    } else {
      formData.append("contactName", data.contactName);
      formData.append("contactEmail", data.contactEmail);
      formData.append("contactPhone", data.contactPhone);
      formData.append("addressDetails", data.addressDetails);
      formData.append("nearestLandmark", data.nearestLandmark);
      formData.append("cityId", data.cityId);
    }
    cart.forEach((product, index) => {
      formData.append(`products[${index}].productId`, product.productId);
      formData.append(`products[${index}].count`, product.count);
    });
    Axios.post(API.orders.route, formData)
      .then((response) => {
        if (isAuthenticated) {
          if (userId) {
            Axios.delete(API.users.clearUserCart + userId)
              .then((response) => {
                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
                viewError(true);
              });
          }
        }
        clearCart();
        props.history.push("/created-successfully");
      })
      .catch((error) => {
        setLoading(false);
        viewError(true);
      });
  };

  const addAddressBox = (
    <div className="row mb-3">
      <div className="col-12">
        <div className={classes.Box}>
          <div className={classes.BoxTitle}>
            <h5>Address Details</h5>
          </div>
          <div className="row my-5">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="addressDetails">
                  <strong>Address Details*</strong>
                </label>
                <input
                  type="text"
                  id="addressDetails"
                  name="addressDetails"
                  className="form-control"
                  placeholder="Address Details"
                  required
                  value={data.addressDetails}
                  onChange={handleChange}
                />
              </div>
              <div className="form-row">
                <div className="col-12 col-md-6">
                  <label htmlFor="nearestLandmark">
                    <strong>Nearest Landmark*</strong>
                  </label>
                  <input
                    type="text"
                    id="nearestLandmark"
                    name="nearestLandmark"
                    className="form-control"
                    placeholder="Nearest Landmark"
                    required
                    value={data.nearestLandmark}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="cityId">
                    <strong>City</strong>
                  </label>
                  <select
                    id="cityId"
                    name="cityId"
                    className="form-control"
                    required
                    value={data.cityId}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Choose...
                    </option>
                    {cities.map((city) => (
                      <option key={"city-" + city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const selectAddressBox = (
    <div className="row mb-3">
      <div className="col-12">
        <div className={classes.Box}>
          <div className={classes.BoxTitle}>
            <h5>Address Details</h5>
          </div>
          <div className="row my-5">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="addressId">
                  <strong>Select Address</strong>
                </label>
                <select
                  id="addressId"
                  name="addressId"
                  className="form-control"
                  required
                  value={data.addressId}
                  onChange={handleChange}
                >
                  {addresses.map((address) => (
                    <option key={"address-" + address.id} value={address.id}>
                      {address.addressDetails + ", " + address.city.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const contactInfoBox = (
    <div className="row mb-3">
      <div className="col-12">
        <div className={classes.Box}>
          <div className={classes.BoxTitle}>
            <h5>Contact Info</h5>
          </div>
          <div className="row my-5">
            <div className="col-12">
              <div className="form-group">
                <input
                  type="text"
                  id="contactName"
                  name="contactName"
                  className="form-control"
                  placeholder="Contact Name"
                  required
                  value={data.contactName}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  id="contactEmail"
                  name="contactEmail"
                  className="form-control"
                  placeholder="Contact Email"
                  required
                  value={data.contactEmail}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  id="contactPhone"
                  name="contactPhone"
                  className="form-control"
                  placeholder="Contact Phone"
                  required
                  value={data.contactPhone}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <AreaBar title="Checkout" area="Checkout" />
      <Paper className={classes.Checkout} elevation={0}>
        <div className="container py-5">
          <form onSubmit={handleSubmit}>
            <div className="inputsContainer row">
              <div className="col-12 col-lg-8">
                {isAuthenticated ? selectAddressBox : addAddressBox}
                {!isAuthenticated ? contactInfoBox : null}
              </div>
              <div className="col-12 col-lg-4 mb-3">
                <div className={[classes.Box, classes.CartBox].join(" ")}>
                  <div className={classes.BoxTitle}>
                    <h2>Your Order</h2>
                  </div>
                  <ul>
                    {cartProducts.map((cp) => (
                      <li key={"cartProduct-" + cp.id}>
                        <MiniProduct product={cp} />
                      </li>
                    ))}
                  </ul>
                  <ul>
                    <li>
                      <div className="d-flex justify-content-between mt-3">
                        <h3
                          className="text-capitalize mb-0"
                          style={{ color: "#333" }}
                        >
                          Subtotal:
                        </h3>
                        <h3
                          className="text-uppercase mb-0"
                          style={{ color: "#000" }}
                        >
                          {subtotal + " EGP"}
                        </h3>
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <h3
                          className="text-capitalize mb-0"
                          style={{ color: "#333" }}
                        >
                          Shipping:
                        </h3>
                        <h3
                          className="text-uppercase mb-0"
                          style={{ color: "#000" }}
                        >
                          {shipping + " EGP"}
                        </h3>
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <h3
                          className="text-capitalize mb-0"
                          style={{ color: "#333" }}
                        >
                          Order Total:
                        </h3>
                        <h3
                          className="text-uppercase mb-0"
                          style={{ color: "#000" }}
                        >
                          {subtotal + shipping + " EGP"}
                        </h3>
                      </div>
                    </li>
                    <li>
                      <div className="text-center">
                        <Link to="/cart" className="btn">
                          <h5>View Cart</h5>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {subtotal + shipping > 0 ?
              <div>
                <h4 className="text-uppercase mb-0 text-center" style={{ color: "#cf0909" , direction:"rtl" }}>
                  {PAYMENT_EWALETMETHOD_MESSAGE}
                </h4>
                <h3 className="text-uppercase mb-0 text-center" style={{ color: "#5bacd7" , direction:"rtl" }}>
                  - {PAYMENT_EWALETMETHOD1}
                </h3>
                <h3 className="text-uppercase mb-0 text-center" style={{ color: "#5bacd7" , direction:"rtl" }}>
                  - {PAYMENT_EWALETMETHOD2}
                </h3>
                <h4 className="text-uppercase mb-0 text-center" style={{ color: "#cf0909" , direction:"rtl" }}>
                  {PAYMENT_SEND_WHATSMESSAGE}
                </h4>
                <div >
                  <p className="text-uppercase mb-0 text-center"
                    style={{ color: "#cf0909", direction:"rtl" }}
                  >
                    {DELIVERY_MESSAGE}
                  </p>

                </div>
              </div>
              : <h4 className="text-uppercase mb-0 text-center" style={{ color: "#5bacd7" }} title="Free Order">{FREEORDER_MESSAGE}</h4>}
            {cart.length > 0 ? (
              <div className="row pt-5">
                <div className="col">
                  <button
                    type="submit"
                    className={[classes.Btn, classes.FullWidth].join(" ")}
                    disabled={cart.length <= 0}
                  >
                    <strong>Submit Order</strong>
                  </button>
                </div>
              </div>
            ) : null}
          </form>
        </div>
      </Paper>
      {loading && <Loading />}
    </div>
  );
};

export default Checkout;
