import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
} from "@material-ui/core";

import classes from "../../Admin.module.css";
import { API } from "../../../../configs/api";
import { ErrorContext } from "../../../../context/ErrorContext";
import useTable from "../../../Templates/useTable";
import Notification from "../../../Templates/Notification";
import ConfirmDialog from "../../../Templates/ConfirmDialog";
import { Input, ActionButton } from "../../../Controls";
import Loading from "../../../UI/Loading/Loading";
import { Search, Edit, DeleteForever } from "@material-ui/icons";

const headCells = [
  { id: "#", label: "#", disableSorting: true },
  { id: "id", label: "ID" },
  { id: "name", label: "Name" },
  { id: "category", label: "Category" },
  { id: "cover", label: "Cover", disableSorting: true },
  { id: "actions", label: "", disableSorting: true },
];

const ListSubCategories = (props) => {
  const [subCategories, setSubCategories] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const viewError = useContext(ErrorContext).activateError;
  const [loading, setLoading] = useState(false);

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    setPage,
  } = useTable(subCategories, headCells, filterFn);

  useEffect(() => {
    Axios.get(API.subCategories.route)
      .then((response) => {
        setSubCategories(response.data);
      })
      .catch((error) => {
        viewError(true);
      });
  }, [viewError]);

  const handleSearch = (e) => {
    let value = e.target.value.toLowerCase();
    setFilterFn({
      fn: (items) => {
        if (value === "") {
          return items;
        } else {
          return items.filter(
            (item) =>
              String(item.id).toLowerCase().includes(value) ||
              String(item.name).toLowerCase().includes(value) ||
              String(item.category.name).toLowerCase().includes(value)
          );
        }
      },
    });
    setPage(0);
  };

  const handleDelete = (id) => {
    setLoading(true);
    Axios.delete(API.subCategories.route + id)
      .then((response) => {
        setLoading(false);
        const newSubCategories = [...subCategories];
        const deletedIndex = subCategories.findIndex(
          (subCat) => subCat.id === id
        );
        newSubCategories.splice(deletedIndex, 1);
        setSubCategories(newSubCategories);

        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setNotification({
          isOpen: true,
          message: "Deleted Successfully",
          type: "error",
        });
      })
      .catch((error) => {
        setLoading(false);
        viewError(true);
      });
  };

  return (
    <div className={classes.AdminList}>
      <Paper className={classes.PaperContent} elevation={0}>
        <Toolbar className={classes.Toolbar}>
          <Input
            label="Search"
            className={classes.SearchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item, index) => (
              <TableRow key={"subCategory-" + item.id} hover={true}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.category.name}</TableCell>
                <TableCell>
                  <img
                    src={item.cover}
                    alt={item.name}
                    className={classes.ItemImage}
                  />
                </TableCell>
                <TableCell>
                  <Link to={props.match.url + "/" + item.id}>
                    <ActionButton color="primary">
                      <Edit fontSize="small" />
                    </ActionButton>
                  </Link>
                  <ActionButton
                    color="secondary"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this item?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          handleDelete(item.id);
                        },
                      });
                    }}
                  >
                    <DeleteForever fontSize="small" />
                  </ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>

      <Notification
        notification={notification}
        setNotification={setNotification}
      />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {loading && <Loading />}
    </div>
  );
};

export default ListSubCategories;
