import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
      margin: theme.spacing(0.5)
  },
  label: {
      textTransform: 'none'
  }
}))

export const MuiButton = (props) => {
  const { text, size, color, variant, onClick, ...other } = props;
  const muiClasses = useStyles();

  return (
    <Button
      variant={variant || "contained"}
      size={size || "large"}
      color={color || "primary"}
      onClick={onClick}
      {...other}
      classes={{ root: muiClasses.root, label: muiClasses.label }}
    >
      {text}
    </Button>
  );
};
