import React, { useState } from "react";
import Axios from "axios";
import { makeStyles } from "@material-ui/core";

import { API } from "../../../../../configs/api";
import Notification from "../../../../Templates/Notification";
import ConfirmDialog from "../../../../Templates/ConfirmDialog";
import { ActionButton } from "../../../../Controls";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles((theme) => ({
  item: {
    position: "relative",
    width: "15%",
    padding: theme.spacing(1),
  },
  deleteBtn: {
    position: "absolute",
    top: theme.spacing(0),
    right: theme.spacing(0),
  },
}));

const PreviewImages = (props) => {
  const { photos, setPhotos, isStored } = props;
  const muiClasses = useStyles();
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const handleDelete = (photoId) => {
    if (isStored) {
      Axios.delete(API.products.deleteProductPhoto + photoId)
        .then((response) => {
          const newPhotos = [...photos];
          const index = newPhotos.findIndex((photo) => photo.id === photoId);
          newPhotos.splice(index, 1);
          setPhotos(newPhotos);

          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
        })
        .catch((error) => {
          setNotification({
            isOpen: true,
            message: "Something went wrong",
            type: "warning",
          });
        });
    } else {
      const newPhotos = [...photos];
      // here we send the index directly cuz all ids are 0
      // const index = newPhotos.findIndex((photo) => photo.id === photoId);
      const index = photoId;
      newPhotos.splice(index, 1);
      setPhotos(newPhotos);
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
    }
  };

  return (
    <div className="d-flex justify-content-center">
      {photos.map((photo, index) => (
        <div key={`${photo.id}-${index}`} className={muiClasses.item}>
          <div className={muiClasses.deleteBtn}>
            <ActionButton
              color="secondary"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure to delete this item?",
                  subTitle: "You can't undo this operation",
                  onConfirm: () => {
                    isStored ? handleDelete(photo.id) : handleDelete(index);
                  },
                });
              }}
            >
              <HighlightOffIcon fontSize="small" />
            </ActionButton>
          </div>
          <img src={photo.name} alt="" className="img-fluid" />
        </div>
      ))}

      <Notification
        notification={notification}
        setNotification={setNotification}
      />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
};

export default React.memo(PreviewImages);
