import React from "react";
import { Route, Switch } from "react-router-dom";
import { Paper } from "@material-ui/core";

import classes from "./MyAccount.module.css";
import AreaBar from "../../components/AreaBar/AreaBar";
import UserSidePanel from "../../components/MyAccount/UserSidePanel/UserSidePanel";
import AccountInfo from "../../components/MyAccount/AccountInfo/AccountInfo";
import EditInfo from "../../components/MyAccount/AccountInfo/EditInfo/EditInfo";
import ChangePassword from "../../components/MyAccount/AccountInfo/ChangePassword/ChangePassword";
import AddressForm from "../../components/MyAccount/AccountInfo/Address/AddressForm";
import Wishlist from "../../components/MyAccount/Wishlist/Wishlist";
import Orders from "../../components/MyAccount/Orders/Orders";

const MyAccount = () => {
  return (
    <React.Fragment>
      <AreaBar title="My Account" area="My Account" />
      <Paper className="py-5">
        <section className="container-fluid">
          <div className={["row", classes.Content].join(" ")}>
            <div className="col-3">
              <UserSidePanel />
            </div>
            <div className={["col-9", classes.InnerContent].join(" ")}>
              <Switch>
                <Route
                  path="/myaccount/edit-contant-info"
                  component={EditInfo}
                />
                <Route
                  path="/myaccount/change-password"
                  component={ChangePassword}
                />
                <Route path="/myaccount/add-address" component={AddressForm} />
                <Route
                  path="/myaccount/edit-address/:id"
                  component={AddressForm}
                />
                <Route path="/myaccount/wishlist" component={Wishlist} />
                <Route path="/myaccount/myorders" component={Orders} />
                <Route path="/myaccount" component={AccountInfo} />
              </Switch>
            </div>
          </div>
        </section>
      </Paper>
    </React.Fragment>
  );
};

export default MyAccount;
