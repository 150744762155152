import React, { useState, useCallback } from "react";

export const ErrorContext = React.createContext({
  hasError: false,
  activateError: (error) => {},
});

const ErrorContextProvider = (props) => {
  const [error, setError] = useState(false);

  const setErrorHandler = useCallback((error) => {
    setError(error);
  }, []);

  return (
    <ErrorContext.Provider
      value={{ hasError: error, activateError: setErrorHandler }}
    >
      {props.children}
    </ErrorContext.Provider>
  );
};

export default ErrorContextProvider;
