import React from "react";
import { Link } from "react-router-dom";

import classes from "../OrderCard.module.css";
import { getFullDate } from "../../../../../configs/utils";

const DetailsFull = (props) => {
  const { order, setOpenForm } = props;

  let dateOrdered = getFullDate(order.dateOrdered);
  let dateUpdated = getFullDate(order.dateUpdated);
  let dateDelivered = getFullDate(order.dateDelivered);

  return (
    <div className={classes.OrderDetails}>
      <div className={classes.Detail}>
        <h4>Contact Info</h4>
        <p className="mb-1">
          Name: <strong>{order.contactName}</strong>
        </p>
        <p className="mb-1">
          Email: <strong>{order.contactEmail}</strong>
        </p>
        <p className="mb-1">
          Phone: <strong>{order.contactPhone}</strong>
        </p>
      </div>
      <div className={classes.Detail}>
        <h4>Address Details</h4>
        <p className="mb-1">
          Address: <strong>{order.addressDetails}</strong>
        </p>
        <p className="mb-1">
          City: <strong>{order.city}</strong>
        </p>
        <p className="mb-1">
          Nearest Landmark: <strong>{order.nearestLandmark}</strong>
        </p>
      </div>
      <div className={classes.Detail}>
        <h4>Order Summary</h4>
        <p className="mb-1">
          Order ID: <strong>{order.id}</strong>
        </p>
        <p className="mb-1">
          Order Status: <strong>{order.orderStatus}</strong>
          {" | "}
          <span>
            <Link to="#" onClick={() => setOpenForm(true)}>
              Update Status
            </Link>
          </span>
        </p>
        <p className="mb-1">
          Date Ordered: <strong>{dateOrdered}</strong>
        </p>
        <p className="mb-1">
          Last Updated: <strong>{dateUpdated}</strong>
        </p>
        <p className="mb-1">
          Date Delivered:{" "}
          <strong>
            {order.orderStatus.toLowerCase() === "delivered"
              ? dateDelivered
              : "Not Delivered"}
          </strong>
        </p>
        <div className="mt-5">
          <p>
            Subtotal: <strong>{order.subtotal}</strong>
          </p>
          <p>
            Shipping: <strong>{order.shipping}</strong>
          </p>
          <p>
            Total Price: <strong>{order.totalPrice}</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DetailsFull;
