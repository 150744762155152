import React, { useState, useCallback } from "react";
import jwt_decode from "jwt-decode";

export const AuthContext = React.createContext({
  userData: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    roles: [],
  },
  wishlist: [],
  token: "",
  message: "",
  isAuthenticated: false,
  authenticationHandler: (authData) => {},
  manualLogin: (token) => {},
  logout: () => {},
  setWishlist: (wishlist) => {},
  addToWishlist: (wishlistItem) => {},
  removeFromWishlist: (productId) => {},
});

const initialState = {
  userData: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    roles: [],
  },
  token: "",
  message: "",
  isAuthenticated: false,
};

const AuthContextProvider = (props) => {
  const [authState, setAuthState] = useState(initialState);
  const [wishlist, setWishlist] = useState([]);

  const setAuthStateHandler = useCallback((state) => {
    setAuthState(state);
    if (state.token) {
      const { exp } = jwt_decode(state.token);
      localStorage.setItem("token", state.token);
      localStorage.setItem("expirationDate", exp * 1000);
    }
  }, []);

  const manualLoginHandler = useCallback(
    (token) => {
      const decoded_data = jwt_decode(token);
      const tokenData = {
        id: decoded_data.id,
        firstName: decoded_data.firstName,
        lastName: decoded_data.lastName,
        email: decoded_data.email,
        phone: decoded_data.phone,
        roles: Array.isArray(decoded_data.roles)
          ? decoded_data.roles
          : [decoded_data.roles],
      };
      const state = {
        userData: tokenData,
        token: token,
        message: "Successfully logged in",
        isAuthenticated: true,
      };
      setAuthStateHandler(state);
    },
    [setAuthStateHandler]
  );

  const logoutHandler = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("expirationDate");
    setAuthState(initialState);
  }, []);

  const wishlistHandler = useCallback((wishlist) => {
    setWishlist(wishlist);
  }, []);

  const addToWishlist = (wishlistItem) => {
    setWishlist((prevList) => {
      const newList = [];
      prevList.forEach((item) => {
        newList.push({ ...item });
      });
      newList.push({ ...wishlistItem });
      return newList;
    });
  };

  const removeFromWishlist = (productId) => {
    setWishlist((prevList) => {
      const newList = [];
      prevList.forEach((item) => {
        newList.push({ ...item });
      });
      const index = newList.findIndex((item) => item.productId === productId);
      if (index > -1) {
        newList.splice(index, 1);
      }
      return newList;
    });
  };

  return (
    <AuthContext.Provider
      value={{
        userData: authState.userData,
        wishlist: wishlist,
        token: authState.token,
        isAuthenticated: authState.isAuthenticated,
        authenticationHandler: setAuthStateHandler,
        manualLogin: manualLoginHandler,
        logout: logoutHandler,
        setWishlist: wishlistHandler,
        addToWishlist: addToWishlist,
        removeFromWishlist: removeFromWishlist,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
