import React, { useState, useEffect } from "react";
import Axios from "axios";

import classes from "../../Admin.module.css";
import { API } from "../../../../configs/api";
import Notification from "../../../Templates/Notification";
import Button from "../../../UI/Button/Button";
import Loading from "../../../UI/Loading/Loading";

const OrderForm = (props) => {
  const { order, onUpdateOrder, statuses } = props;
  const [loading, setLoading] = useState(false);
  const [orderStatusId, setOrderStatusId] = useState(0);
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    let currentStatus = statuses.find((status) => {
      return status.name === order.orderStatus;
    });
    setOrderStatusId(currentStatus.id);
  }, [statuses, order.orderStatus]);

  const handleChange = (e) => {
    const { value } = e.target;
    setOrderStatusId(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("statusId", orderStatusId);

    Axios.put(API.orders.updateOrderStatus + order.id, formData)
      .then((response) => {
        setLoading(false);
        onUpdateOrder();
      })
      .catch((error) => {
        setLoading(false);
        setNotification({
          isOpen: true,
          message: "Something went Wrong",
          type: "warning",
        });
      });
  };

  return (
    <div className={classes.AdminForm}>
      <form onSubmit={handleSubmit}>
        <div className="inputsContainer row">
          <div className="col-12 text-center">
            <div className="col-12 p-3">
              <label htmlFor="status">
                <strong>Status</strong>
              </label>
              <select
                id="status"
                name="status"
                className="form-control"
                value={orderStatusId}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Choose...
                </option>
                {statuses.map((status) => (
                  <option key={"status-" + status.id} value={status.id}>
                    {status.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-12 text-center">
            <Button type="submit">
              <strong>Update</strong>
            </Button>
          </div>
        </div>
      </form>
      <Notification
        notification={notification}
        setNotification={setNotification}
      />
      {loading && <Loading />}
    </div>
  );
};

export default OrderForm;
