import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { Paper } from "@material-ui/core";

import classes from "./Cart.module.css";
import { API } from "../../../configs/api";
import { CartContext } from "../../../context/CartContext";
import AreaBar from "../../AreaBar/AreaBar";
import CartProduct from "./CartProduct/CartProduct";

const Cart = () => {
  const [cartProducts, setCartProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const cart = useContext(CartContext).cart;

  useEffect(() => {
    if (cart.length > 0) {
      const formData = new FormData();
      cart.forEach((product, index) =>
        formData.append(`ids[${index}]`, product.productId)
      );
      Axios.post(API.products.getProductsByIds, formData)
        .then((response) => {
          const finalProducts = [];
          const products = response.data;
          cart.forEach((cp) => {
            // get the specific product in response
            const product = products.find((p) => p.id === cp.productId);
            if (product) {
                product.count = cp.count;
                finalProducts.push({ ...product });
            }
          });
          setCartProducts(finalProducts);
        })
        .catch((error) => {});
    } else {
      setCartProducts([]);
      setTotalPrice(0);
    }
  }, [cart]);

  useEffect(() => {
    if (cartProducts.length > 0) {
      let total = 0;
      cartProducts.forEach((product) => {
        let price = product.hasOffer
          ? product.price - product.offer
          : product.price;
        price = price * product.count;
        total += price;
      });
      setTotalPrice(total);
    }
  }, [cartProducts]);

  return (
    <div>
      <AreaBar title="Cart" area="Cart" />
      <Paper className={classes.Cart} elevation={0}>
        <div className="container py-5">
          <div className="row">
            <div className="col">
              <ul>
                {cartProducts.map((cp) => (
                  <li key={"cartProduct-" + cp.id}>
                    <CartProduct product={cp} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col d-flex justify-content-end align-items-center">
              <h2 className="mr-3 text-capitalize">Total: </h2>
              <h1 className="ml-3 text-uppercase">{totalPrice + " EGP"}</h1>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col d-flex justify-content-end align-items-center">
              <Link to="/checkout" className={classes.Btn}>
                <strong>Checkout</strong>
              </Link>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default Cart;
