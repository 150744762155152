import React, { useState, useContext, useRef } from "react";
import Axios from "axios";

import classes from "../MyAccount.module.css";
import { AuthContext } from "../../../../context/AuthContext";
import { ErrorContext } from "../../../../context/ErrorContext";
import { API } from "../../../../configs/api";
import Button from "../../../UI/Button/Button";

const defaultData = {
  id: "",
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const ChangePassword = (props) => {
  const [data, setData] = useState(defaultData);
  const [changeError, setChangeError] = useState("");
  const [showErrors, setShowErrors] = useState(classes.Hidden);
  const userId = useContext(AuthContext).userData.id;
  const viewError = useContext(ErrorContext).activateError;
  const confirmPassword = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });

    if (name === "confirmPassword") {
      if (value !== data.newPassword) {
        confirmPassword.current.setCustomValidity("Passwords Don't Match");
      } else {
        confirmPassword.current.setCustomValidity("");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("id", userId);
    formData.append("currentPassword", data.currentPassword);
    formData.append("newPassword", data.newPassword);

    Axios.put(API.users.changePassword + userId, formData)
      .then((response) => {
        
        if (!response.data.isAuthenticated) {
          setChangeError(response.data.message);
          setShowErrors(classes.Visible);
        } else {
          const currentPath = props.location.pathname;
          const parentPath = currentPath.substring(
            0,
            currentPath.lastIndexOf("/")
          );
          props.history.push(parentPath);
        }
      })
      .catch((error) => {
        viewError(true);
      });
  };

  return (
    <div className={classes.MyAccount}>
      <div className="mb-5">
        <h2>Change Password</h2>
      </div>
      <div className="inputsContainer row">
        <div className={[showErrors, "col-12 col-md-7 mb-3"].join(" ")}>
          <div className={classes.Errors}>
            <p className="mb-0">{changeError}</p>
          </div>
        </div>
        <div className="col-12">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-md-7 form-group">
                <label htmlFor="currentPassword">
                  <strong>Current Password*</strong>
                </label>
                <input
                  type="password"
                  id="currentPassword"
                  name="currentPassword"
                  className="form-control"
                  placeholder="Current Password"
                  required
                  value={data.currentPassword}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-7 form-group">
                <label htmlFor="newPassword">
                  <strong>New Password*</strong>
                </label>
                <input
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  className="form-control"
                  placeholder="New Password"
                  minLength="8"
                  required
                  value={data.newPassword}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-7 fomr-group">
                <label htmlFor="confirmPassword">
                  <strong>Confirm Password*</strong>
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  className="form-control"
                  placeholder="Confirm Password"
                  required
                  value={data.confirmPassword}
                  onChange={handleChange}
                  ref={confirmPassword}
                />
              </div>
            </div>
            <div className="row pt-5">
              <div className="col">
                <Button type="submit">
                  <strong>Change Passowrd</strong>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
