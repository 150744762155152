import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import SideMenu from "../../components/Admin/SideMenu/SideMenu";
import StoreInfo from "../../components/Admin/StoreInfo/StoreInfo";
import FeaturedImages from "../../components/Admin/StoreInfo/FeaturedImages/FeaturedImages";
import ListCategories from "../../components/Admin/Categories/ListCategories/ListCategories";
import CategoryForm from "../../components/Admin/Categories/CategoryForm/CategoryForm";
import ListSubCategories from "../../components/Admin/SubCategories/ListSubCategories/ListSubCategories";
import SubCategoryForm from "../../components/Admin/SubCategories/SubCategoryForm/SubCategoryForm";
import ListProducts from "../../components/Admin/Products/ListProducts/ListProducts";
import ProductForm from "../../components/Admin/Products/ProductForm/ProductForm";
import ListUsers from "../../components/Admin/Users/ListUsers";
import ListOrders from "../../components/Admin/Orders/ListOrders";
import ShippingForm from "../../components/Admin/Shipping/ShippingForm";

const Admin = (props) => {
  return (
    <section className="container-fluid mt-5">
      <div className="row">
        <div className="col-3 p-0">
          <SideMenu />
        </div>
        <div className="col-9">
          <Switch>
            {/* Store Info */}
            <Route
              path={props.match.url + "/storeInfo"}
              component={StoreInfo}
            />
            <Route
              path={props.match.url + "/featuredImages"}
              component={FeaturedImages}
            />

            {/* Categories */}
            <Route
              path={props.match.url + "/categories/new"}
              component={CategoryForm}
            />
            <Route
              path={props.match.url + "/categories/:id"}
              component={CategoryForm}
            />
            <Route
              path={props.match.url + "/categories"}
              component={ListCategories}
            />

            {/* SubCategories */}
            <Route
              path={props.match.url + "/subcategories/new"}
              component={SubCategoryForm}
            />
            <Route
              path={props.match.url + "/subcategories/:id"}
              component={SubCategoryForm}
            />
            <Route
              path={props.match.url + "/subcategories"}
              component={ListSubCategories}
            />

            {/* Products */}
            <Route
              path={props.match.url + "/products/new"}
              component={ProductForm}
            />
            <Route
              path={props.match.url + "/products/:id"}
              component={ProductForm}
            />
            <Route
              path={props.match.url + "/products"}
              component={ListProducts}
            />

            {/* Users */}
            <Route 
              path={props.match.url + "/users"}
              component={ListUsers}
            />
            
            {/* Orders */}
            <Route 
              path={props.match.url + "/orders"}
              component={ListOrders}
            />

            {/* Shipping Fees */}
            <Route 
              path={props.match.url + "/shipping"}
              component={ShippingForm}
            />

            <Route exact path={props.match.url}>
              <Redirect to={props.match.url + "/storeInfo"} />
            </Route>
          </Switch>
        </div>
      </div>
    </section>
  );
};

export default Admin;
