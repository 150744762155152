import React from "react";
import { Link } from "react-router-dom";

const MiniProduct = (props) => {
  const finalPrice = props.product.hasOffer
    ? props.product.price - props.product.offer
    : props.product.price;

  return (
    <div className="row m-0">
      <div className="col-4">
        <Link to={"/product/" + props.product.id}>
          <img
            src={props.product.photos[0].name}
            alt={props.product.name}
            className="img-fluid"
          />
        </Link>
      </div>
      <div className="col-8">
        <h3 className="mb-3">
          <Link to={"/product/" + props.product.id}>{props.product.name}</Link>
        </h3>
        <h4>
          {props.product.count} X {finalPrice}
        </h4>
      </div>
    </div>
  );
};

export default React.memo(MiniProduct);
