import React, { useState } from "react";
import { NavLink, withRouter } from "react-router-dom";

import classes from "./SideMenu.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const defaultLinks = {
  classes: [classes.SubLinks].join(" "),
  arrow: [classes.ArrowIcon].join(" "),
  active: false,
};

const activeLinks = {
  classes: [classes.SubLinks, classes.Shown].join(" "),
  arrow: [classes.ArrowIcon, classes.RotatedArrow].join(" "),
  active: true,
};

const infoArrow = "info";
const productsArrow = "products";
const categoriesArrow = "categories";
const subCategoriesArrow = "subCategories";
const ordersArrow = "orders";
const usersArrow = "users";
const shippingArrow = "shipping";

const SideMenu = (props) => {
  const [infoLinks, setInfoLinks] = useState(defaultLinks);
  const [productsLinks, setProductsLinks] = useState(defaultLinks);
  const [categoriesLinks, setCategoriesLinks] = useState(defaultLinks);
  const [subCategoriesLinks, setSubCategoriesLinks] = useState(defaultLinks);
  const [ordersLinks, setOrdersLinks] = useState(defaultLinks);
  const [usersLinks, setUsersLinks] = useState(defaultLinks);
  const [shippingLinks, setShippingLinks] = useState(defaultLinks);

  const handleArrowClick = (clickedArrow) => {
    switch (clickedArrow) {
      case infoArrow:
        infoLinks.active
          ? setInfoLinks(defaultLinks)
          : setInfoLinks(activeLinks);
        break;
      case productsArrow:
        productsLinks.active
          ? setProductsLinks(defaultLinks)
          : setProductsLinks(activeLinks);
        break;
      case categoriesArrow:
        categoriesLinks.active
          ? setCategoriesLinks(defaultLinks)
          : setCategoriesLinks(activeLinks);
        break;
      case subCategoriesArrow:
        subCategoriesLinks.active
          ? setSubCategoriesLinks(defaultLinks)
          : setSubCategoriesLinks(activeLinks);
        break;
      case ordersArrow:
        ordersLinks.active
          ? setOrdersLinks(defaultLinks)
          : setOrdersLinks(activeLinks);
        break;
      case usersArrow:
        usersLinks.active
          ? setUsersLinks(defaultLinks)
          : setUsersLinks(activeLinks);
        break;
      case shippingArrow:
        shippingLinks.active
          ? setShippingLinks(defaultLinks)
          : setShippingLinks(activeLinks);
        break;

      default:
        break;
    }
  };

  return (
    <div className={classes.SideMenu}>
      <ul className={classes.Links}>
        {/* info */}
        <li>
          <div className={classes.Link}>
            <NavLink
              to={props.match.url + "/storeInfo"}
              activeClassName={classes.Active}
            >
              Store Info
            </NavLink>
            <div
              className={classes.Arrow}
              onClick={() => handleArrowClick(infoArrow)}
            >
              <FontAwesomeIcon icon={faAngleLeft} className={infoLinks.arrow} />
            </div>
          </div>
          <ul className={infoLinks.classes}>
            <li className={[classes.Link, classes.SubLink].join(" ")}>
              <NavLink
                to={props.match.url + "/featuredImages"}
                activeClassName={classes.Active}
              >
                Featured Images
              </NavLink>
            </li>
          </ul>
        </li>

        {/* categories */}
        <li>
          <div className={classes.Link}>
            <NavLink
              to={props.match.url + "/categories"}
              activeClassName={classes.Active}
            >
              Categories
            </NavLink>
            <div
              className={classes.Arrow}
              onClick={() => handleArrowClick(categoriesArrow)}
            >
              <FontAwesomeIcon
                icon={faAngleLeft}
                className={categoriesLinks.arrow}
              />
            </div>
          </div>
          <ul className={categoriesLinks.classes}>
            <li className={[classes.Link, classes.SubLink].join(" ")}>
              <NavLink
                to={props.match.url + "/categories/new"}
                activeClassName={classes.Active}
              >
                Add Category
              </NavLink>
            </li>
          </ul>
        </li>

        {/* sub categories */}
        <li>
          <div className={classes.Link}>
            <NavLink
              to={props.match.url + "/subcategories"}
              activeClassName={classes.Active}
            >
              Sub Categories
            </NavLink>
            <div
              className={classes.Arrow}
              onClick={() => handleArrowClick(subCategoriesArrow)}
            >
              <FontAwesomeIcon
                icon={faAngleLeft}
                className={subCategoriesLinks.arrow}
              />
            </div>
          </div>
          <ul className={subCategoriesLinks.classes}>
            <li className={[classes.Link, classes.SubLink].join(" ")}>
              <NavLink
                to={props.match.url + "/subcategories/new"}
                activeClassName={classes.Active}
              >
                Add Sub Category
              </NavLink>
            </li>
          </ul>
        </li>

        {/* products */}
        <li>
          <div className={classes.Link}>
            <NavLink
              to={props.match.url + "/products"}
              activeClassName={classes.Active}
            >
              Products
            </NavLink>
            <div
              className={classes.Arrow}
              onClick={() => handleArrowClick(productsArrow)}
            >
              <FontAwesomeIcon
                icon={faAngleLeft}
                className={productsLinks.arrow}
              />
            </div>
          </div>
          <ul className={productsLinks.classes}>
            <li className={[classes.Link, classes.SubLink].join(" ")}>
              <NavLink
                to={props.match.url + "/products/new"}
                activeClassName={classes.Active}
              >
                Add Product
              </NavLink>
            </li>
          </ul>
        </li>

        {/* users */}
        <li>
          <div className={classes.Link}>
            <NavLink
              to={props.match.url + "/users"}
              activeClassName={classes.Active}
            >
              Users
            </NavLink>
          </div>
        </li>

        {/* orders */}
        <li>
          <div className={classes.Link}>
            <NavLink
              to={props.match.url + "/orders"}
              activeClassName={classes.Active}
            >
              Orders
            </NavLink>
          </div>
        </li>

        {/* shipping */}
        <li>
          <div className={classes.Link}>
            <NavLink
              to={props.match.url + "/shipping"}
              activeClassName={classes.Active}
            >
              Shipping Fees
            </NavLink>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default withRouter(SideMenu);
