import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Paper } from "@material-ui/core";

import { API } from "../../configs/api";
import AreaBar from "../../components/AreaBar/AreaBar";
import ListProducts from "../../components/ListProducts/ListProducts";
import { APPROVED_PRODUCTS } from "../../configs/constants";

const Products = (props) => {
  const { categoryId, subCategoryId, vendorId } = props.match.params;
  const [products, setProducts] = useState([]);
  const [approvedProducts, setApprovedProducts] = useState([]);
  const [title, setTtile] = useState("Products");

  useEffect(() => {
    if (subCategoryId) {
      Axios.get(API.products.getBySubCategoryId + subCategoryId)
        .then((response) => {
          
          setProducts(response.data);
        })
        .catch((error) => {});
      Axios.get(API.subCategories.route + subCategoryId)
        .then((response) => {
          
          setTtile(response.data.name);
        })
        .catch((error) => {});
    } else if (categoryId) {
      if (isNaN(+categoryId) || +categoryId <= 0) {
        props.history.goBack();
      }
      Axios.get(API.products.getByCategoryId + categoryId)
        .then((response) => {
          setProducts(response.data);
        })
        .catch((error) => {});
      Axios.get(API.categories.route + categoryId)
        .then((response) => {
          setTtile(response.data.name);
        })
        .catch((error) => {});
    } else if (vendorId) {
      Axios.get(API.products.getByVendroId + vendorId)
        .then((response) => {
          
          setProducts(response.data);
        })
        .catch((error) => {});
      Axios.get(API.users.getUser + vendorId)
        .then((response) => {
          
          setTtile(response.data.firstName);
        })
        .catch((error) => {});
    }
  }, [categoryId, subCategoryId, vendorId, props.history]);

  useEffect(() => {
    const approvedProducts = products.filter(
      (product) => product.status.name === APPROVED_PRODUCTS
    );
    setApprovedProducts(approvedProducts);
  }, [products]);

  return (
    <React.Fragment>
      <AreaBar title={title} area="Products" />

      <Paper className="py-5">
        <div className="container">
          {approvedProducts.length > 0 ? (
            <ListProducts products={approvedProducts} />
          ) : (
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 className={"text-center p-5"}>
                    No Items to view yet, come back soon
                  </h3>
                </div>
              </div>
            </div>
          )}
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default Products;
