import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { Paper } from "@material-ui/core";

import classes from "../Auth.module.css";
import { ErrorContext } from "../../../context/ErrorContext";
import { AuthContext } from "../../../context/AuthContext";
import { API } from "../../../configs/api";
import AreaBar from "../../AreaBar/AreaBar";
import Button from "../../UI/Button/Button";

const initialData = {
  email: "",
  password: "",
};

const Login = (props) => {
  const [data, setData] = useState(initialData);
  const [loginError, setLoginError] = useState("");
  const [showErrors, setShowErrors] = useState(classes.Hidden);
  const setUser = useContext(AuthContext).authenticationHandler;
  const viewError = useContext(ErrorContext).activateError;

  useEffect(() => {
    if (localStorage.getItem("token")) {
      props.history.replace("/");
    }
  }, [props.history]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleRegisterClick = () => {
    const currentPath = props.location.pathname;
    const parentPath = currentPath.substring(0, currentPath.lastIndexOf("/"));
    props.history.push(parentPath + "/Register");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("password", data.password);

    Axios.post(API.users.login, formData)
      .then((response) => {
        if (!response.data.isAuthenticated) {
          setLoginError(response.data.message);
          setShowErrors(classes.Visible);
        } else {
          setUser(response.data);
          props.history.push("/");
        }
      })
      .catch((error) => {
        viewError(true);
      });
  };

  return (
    <React.Fragment>
      <AreaBar title="Login" area="Login" />

      <Paper className="p-5">
        <div className={classes.Auth}>
          <div className="container-fluid p-5">
            <div className="row">
              <div className={[classes.Box, "col-12 col-md-6"].join(" ")}>
                <div className="row">
                  <div className={[showErrors, "col-12 mb-3"].join(" ")}>
                    <div className={classes.Errors}>
                      <p className="mb-0">{loginError}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h2 className={classes.BoxTitle}>Login</h2>
                  </div>
                </div>
                <div className="inputsContainer row">
                  <div className="col">
                    <div className={classes.BoxContent}>
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="email">
                            <strong>Email</strong>
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="example@example.com"
                            required
                            value={data.email}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="password">
                            <strong>Password</strong>
                          </label>
                          <input
                            type="password"
                            id="password"
                            name="password"
                            className="form-control"
                            placeholder="Password"
                            minLength="8"
                            required
                            value={data.password}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="row pt-5">
                          <div className="col">
                            <Button type="submit">
                              <strong>Login</strong>
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={[
                  classes.Box,
                  "col-12 col-md-6 d-flex flex-column",
                ].join(" ")}
              >
                <div className="row">
                  <div className="col">
                    <h2 className={classes.BoxTitle}>New Customer</h2>
                  </div>
                </div>
                <div className="row flex-fill">
                  <div className="col">
                    <div className={classes.BoxContent}>
                      <p>Don't have an account?</p>
                      <div className="row pt-5">
                        <div className="col">
                          <Button type="submit" clicked={handleRegisterClick}>
                            <strong>Create an Account</strong>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default Login;
