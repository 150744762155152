import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { ErrorContext } from "../../../context/ErrorContext";

import "./modal.css";

const ErrorModal = () => {
  const show = useContext(ErrorContext).hasError;
  const setShow = useContext(ErrorContext).activateError;
  const handleModalClose = () => setShow(false);
  // const handleModalShow = () => setShowModal(true);

  return (
    <div>
      <Modal show={show} onHide={handleModalClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>Something went wrong!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ErrorModal;
