import React from "react";
import { NavLink } from "react-router-dom";

import classes from "./PanelLink.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const PanelLink = (props) => {
  return (
    <NavLink
      to={props.link}
      exact={props.exact}
      className={classes.PanelLink}
      activeClassName={classes.Active}
    >
      <span className={classes.Arrow}>
        <FontAwesomeIcon icon={faAngleRight} />
      </span>
      {props.children}
    </NavLink>
  );
};

export default PanelLink;
