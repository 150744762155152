import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";

import classes from "./MyAccount.module.css";
import { AuthContext } from "../../../context/AuthContext";
import { ErrorContext } from "../../../context/ErrorContext";
import { API } from "../../../configs/api";
import { Vendor_Role } from "../../../configs/constants";
import defaultImage from "../../../assets/images/imgplaceholder.jpg";

const AccountInfo = () => {
  const userId = useContext(AuthContext).userData.id;
  const [userData, setUserData] = useState(useContext(AuthContext).userData);
  const [userAddresses, setUserAddresses] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const isVendor = userData.roles.includes(Vendor_Role);
  const viewError = useContext(ErrorContext).activateError;

  useEffect(() => {
    if (userId) {
      Axios.get(API.users.getUser + userId)
        .then((response) => {
          setUserData({
            ...response.data,
            profilePictureSrc: response.data.profilePicture
              ? response.data.profilePicture
              : defaultImage,
          });
          setLoaded(true);
        })
        .catch((error) => {
          viewError(true);
        });

      Axios.get(API.addresses.getUserAddresses + userId)
        .then((response) => {
          setUserAddresses(response.data);
          
        })
        .catch((error) => {
          viewError(true);
        });
    }
  }, [userId, viewError]);

  const deleteAddressHandler = (addressId) => {
    Axios.delete(API.addresses.route + addressId)
      .then((respone) => {
        const newAddresses = [...userAddresses];
        const index = newAddresses.findIndex((a) => a.id === +addressId);
        newAddresses.splice(index, 1);
        setUserAddresses(newAddresses);
      })
      .catch((error) => {
        viewError(true);
      });
  };

  return (
    <div className={classes.AccountInfo}>
      <div>
        <h2>Account Information</h2>
      </div>
      <div className="row">
        <div className={["col-12", classes.Box].join(" ")}>
          <div className={classes.BoxTitle}>
            <h4>Contact Information</h4>
            <h4>
              <Link to="/myaccount/edit-contant-info">Edit</Link>
            </h4>
          </div>
          {loaded && (
            <div className={classes.BoxContent}>
              <h4>
                <strong>Name: </strong>
                {userData.firstName} {!isVendor && ` ${userData.lastName}`}
              </h4>
              <h4>
                <strong>Email: </strong> {userData.email}
              </h4>
              <h4>
                <strong>Phone Number: </strong> {userData.phone}
              </h4>
              <h4>
                <Link to="/myaccount/change-password">Change Password</Link>
              </h4>
            </div>
          )}
        </div>

        <div className={["col-12", classes.Box].join(" ")}>
          <div className={classes.BoxTitle}>
            <h4>My Addresses</h4>
            <h4>
              <Link to="/myaccount/add-address">Add New Address</Link>
            </h4>
          </div>
          {userAddresses.map((address) => (
            <div
              key={"address-" + address.id}
              className={[classes.BoxContent, classes.AddressBox].join(" ")}
            >
              <h4>
                <strong>Address Details: </strong> {address.addressDetails}
              </h4>
              <h4>
                <strong>District: </strong> {address.city.name}
              </h4>
              <h4>
                <strong>Nearest LandMark: </strong> {address.nearestLandmark}
              </h4>
              <div className="d-flex">
                <h4 className="pr-5">
                  <Link to={"/myaccount/edit-address/" + address.id}>
                    Edit Address
                  </Link>
                </h4>
                <h4>
                  <button
                    type="button"
                    className={"text-danger"}
                    onClick={() => deleteAddressHandler(address.id)}
                  >
                    Delete Address
                  </button>
                </h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;
