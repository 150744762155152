import React, { useState, useEffect } from "react";
import Axios from "axios";

import classes from "../Admin.module.css";
import { API } from "../../../configs/api";
import { MuiCheckbox } from "../../Controls";
import Notification from "../../Templates/Notification";
import Button from "../../UI/Button/Button";
import Loading from "../../UI/Loading/Loading";

const UserForm = (props) => {
  const { user, onUpdateUser } = props;
  const [roles, setRoles] = useState([]);
  const [userRoles, setUserRoles] = useState(() => {
    let rolesObject = {};
    roles.forEach((role) => {
      rolesObject[role] = false;
    });
    user.roles.forEach((role) => {
      rolesObject[role] = true;
    });
    return rolesObject;
  });
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Axios.get(API.users.getAllRoles)
      .then((response) => {
        setRoles(response.data);
        setUserRoles(() => {
          let rolesObject = {};
          response.data.forEach((role) => {
            rolesObject[role] = false;
          });
          user.roles.forEach((role) => {
            rolesObject[role] = true;
          });
          return rolesObject;
        });
      })
      .catch((error) => {
        setNotification({
          isOpen: true,
          message: "Something went Wrong",
          type: "warning",
        });
      });
  }, [user.roles]);

  const handleChange = (e) => {
    const { value, checked } = e.target;
    const currentRoles = { ...userRoles };
    currentRoles[value] = checked;
    setUserRoles(currentRoles);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    let index = 0;
    for (const role in userRoles) {
      if (userRoles[role]) {
        formData.append(`userRoles[${index}]`, role);
        index++;
      }
    }

    Axios.put(API.users.AssignRoles + user.id, formData)
      .then((response) => {
        setLoading(false);
        onUpdateUser();
      })
      .catch((error) => {
        setLoading(false);
        setNotification({
          isOpen: true,
          message: "Something went Wrong",
          type: "warning",
        });
      });
  };

  return (
    <div className={classes.AdminForm}>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 text-center">
            {/* check boxes goes here */}
            {roles.map((role) => (
              <MuiCheckbox
                key={role}
                color="primary"
                name={role}
                label={role}
                value={role}
                checked={userRoles[role] ? userRoles[role] : false}
                onChange={handleChange}
              />
            ))}
          </div>
          <div className="col-12 text-center">
            <Button type="submit">
              <strong>Save</strong>
            </Button>
          </div>
        </div>
      </form>
      <Notification
        notification={notification}
        setNotification={setNotification}
      />
      {loading && <Loading />}
    </div>
  );
};

export default React.memo(UserForm);
