import React, { useState, useEffect, useContext, useCallback } from "react";
import Axios from "axios";
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";

import { API } from "../../../configs/api";
import { AuthContext } from "../../../context/AuthContext";
import OrderCard from "./OrderCard/OrderCard";
import useTable from "../../Templates/useTable";
import Popup from "../../Templates/Popup";
import Notification from "../../Templates/Notification";
import { Input } from "../../Controls";
import { Search } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  SearchInput: {
    width: "40%",
  },
}));

const headCells = [{ id: "actions", label: "", disableSorting: true }];

const Orders = (props) => {
  const muiClasses = useStyles();
  const userId = useContext(AuthContext).userData.id;
  const [orders, setOrders] = useState([]);
  const [orderToView, setOrderToView] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    setPage,
  } = useTable(orders, headCells, filterFn);

  const updateOrders = useCallback(() => {
    console.log(userId)
    Axios.get(API.orders.getUserOrders + userId)
      .then((response) => {
        setOrders(response.data);
      })
      .catch((error) => {});
  }, [userId]);

  useEffect(() => {
    updateOrders();
  }, [updateOrders]);

  const updateOrderToView = () => {
    Axios.get(API.orders.route + orderToView.id)
      .then((response) => {
        setOrderToView(response.data);
      })
      .catch((error) => {});
  };

  const openInPopup = (item) => {
    setOrderToView(item);
    setOpenPopup(true);
  };

  const handleSearch = (e) => {
    let value = e.target.value.toLowerCase();
    setFilterFn({
      fn: (items) => {
        if (value === "") {
          return items;
        } else {
          return items.filter(
            (item) =>
              String(item.id).toLowerCase().includes(value) ||
              String(item.contactName).toLowerCase().includes(value) ||
              String(item.contactEmail).toLowerCase().includes(value) ||
              String(item.contactPhone).toLowerCase().includes(value) ||
              String(item.orderStatus).toLowerCase().includes(value) ||
              String(item.totalPrice).toLowerCase().includes(value)
          );
        }
      },
    });
    setPage(0);
  };

  const onUpdateOrder = () => {
    setNotification({
      isOpen: true,
      message: "Order Canceled",
      type: "warning",
    });
    updateOrders();
    updateOrderToView();
  };

  return (
    <div>
      <Paper elevation={0}>
        <Toolbar>
          <Input
            label="Search"
            className={muiClasses.SearchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item, index) => (
              <TableRow key={"order-" + item.id} hover={true}>
                <TableCell>
                  <OrderCard order={item} openInPopup={openInPopup} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>

      <Popup
        title={"Order: #" + (orderToView ? orderToView.id : null)}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <OrderCard
          order={orderToView}
          onUpdateOrder={onUpdateOrder}
          full={true}
        />
      </Popup>

      <Notification
        notification={notification}
        setNotification={setNotification}
      />
    </div>
  );
};

export default Orders;
