import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { API } from "../../configs/api";
import HeaderSlider from "../../components/HeaderSlider/HeaderSlider";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import ProductCard from "../../components/ListProducts/ProductCard/ProductCard";
import ListCategories from "../../components/ListCategories/ListCategories";

const Home = (props) => {
  const [products, setProducts] = useState([]);
  const [featuredImages, setFeaturedImages] = useState([]);
  const history = useHistory();

  useEffect(() => {
    // Re-order Array Rondomly
    function shuffleArray(array) {
      let i = array.length - 1;
      for (; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array.slice(0, 12);
    }
    // latest products
    Axios.get(API.products.getLatestProducts)
      .then((response) => {
        console.log(response.data)
        setProducts(shuffleArray(response.data));
      })
      .catch((error) => {});

    // featuredImages
    Axios.get(API.featuredImages.route)
      .then((response) => {
        setFeaturedImages(response.data);
      })
      .catch((error) => {});
  }, []);

  return (
    <div>
      <HeaderSlider featuredImages={featuredImages} />

      <Paper className="py-5">
        <section className="container mt-5 mb-5">
          <div className="row">
            <div className="col-12">
              <SectionTitle
                title={"Latest Products"}
                description={"Checkout Our Latest Products"}
              />
            </div>
          </div>
          <div className="row justify-content-around">
            {products.map((product) => (
              <div
                className="col-12 col-md-6 col-lg-4"
                key={"product-" + product.id}
              >
                <ProductCard product={product} />
              </div>
            ))}
          </div>
          <div className="text-center">
            <a className="load-more-btt" href="category/all/1">
            <strong>More Products</strong>
          </a>
          </div>
          
        </section>

        <section className="container mt-5 mb-5">
          <div className="row">
            <div className="col-12">
              <SectionTitle
                title="Categories"
                description="Everything You Want"
              />
            </div>
          </div>
          <ListCategories categories={props.categories} />
        </section>
      </Paper>
    </div>
  );
};

export default Home;
