import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Paper } from "@material-ui/core";

import { API } from "../../configs/api";
import AreaBar from "../../components/AreaBar/AreaBar";
import ListVendors from "../../components/ListVendors/ListVendors";
import { Admin_Role } from "../../configs/constants";

const Vendors = () => {
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    Axios.get(API.users.getVendors)
      .then((response) => {
        
        const filteredVendors = response.data.filter((vendor) => {
          return !vendor.roles.includes(Admin_Role);
        });
        setVendors(filteredVendors);
      })
      .catch((error) => {});
  }, []);

  return (
    <React.Fragment>
      <AreaBar title="Vendors" area="Vendors" />

      <Paper className="py-5">
        <div className="container">
          <ListVendors vendors={vendors} />
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default Vendors;
