import React from "react";
import { NavLink } from "react-router-dom";

import classes from "./NavItem.module.css";

const NavItem = (props) => {
  const { category } = props;

  return (
    <div className={classes.Dropdown}>
      <NavLink
        to={`/category/all/${category.id}`}
        className={classes.NavItem}
        activeClassName={classes.active}
      >
        {category.name}
      </NavLink>
      {category.subCategories.length > 0 && (
        <div className={classes.DropdownContent}>
          {category.subCategories.map((subCat) => (
            <NavLink
              key={"subCat-" + subCat.id}
              to={`/subcategory/${subCat.id}`}
              className={classes.NavItem}
              activeClassName={classes.active}
            >
              {subCat.name}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

export default NavItem;
