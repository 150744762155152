import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Paper } from "@material-ui/core";

import { API } from "../../configs/api";
import AreaBar from "../../components/AreaBar/AreaBar";
import SectionTitle from "../../components/SectionTitle/SectionTitle";

const Terms = (props) => {
  const [terms, setTerms] = useState("");

  useEffect(() => {
    // terms
    Axios.get(API.storeInfo.route)
      .then((response) => {
        setTerms(response.data.terms);
      })
      .catch((error) => {});
  }, []);

  return (
    <div>
      <AreaBar title="Terms Of Service" area="Terms Of Service" />
      <Paper className="py-5">
        <section className="container mt-5 mb-5">
          <div className="row">
            <div className="col-12">
              <SectionTitle
                title={"Terms Of Service"}
                // description={"Terms Of Service"}
              />
            </div>
          </div>
          <div className="row justify-content-start">
            <p
              style={{
                textAlign: "start",
                unicodeBidi: "plaintext",
              }}
            >
              {terms}
            </p>
          </div>
        </section>
      </Paper>
    </div>
  );
};

export default Terms;
