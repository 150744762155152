import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Paper } from "@material-ui/core";

import { API } from "../../configs/api";
import AreaBar from "../../components/AreaBar/AreaBar";
import ListSubCategories from "../../components/ListSubCategories/ListSubCategories";

const SubCategories = (props) => {
  const { categoryId } = props.match.params;
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    if (categoryId) {
      if (isNaN(+categoryId) || +categoryId <= 0) {
        props.history.goBack();
      }
      Axios.get(API.subCategories.getByCategoryId + categoryId)
        .then((response) => {
          setSubCategories(response.data);
        })
        .catch((error) => {});
    }
  }, [categoryId, props.history]);

  return (
    <React.Fragment>
      {subCategories.length > 0 ? (
        <AreaBar title={subCategories[0].category.name} area="Categories" />
      ) : (
        <AreaBar title="Categories" area="Categories" />
      )}

      <Paper className="py-5">
        <div className="container">
          <ListSubCategories subCategories={subCategories} />
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default SubCategories;
