import React from "react";
import PropTypes from "prop-types";

import classes from "./SectionTitle.module.css";

const SectionTitle = (props) => {
  return (
    <div className={classes.SectionTitle}>
      <h2>{props.title}</h2>
      {props.description ? <p>{props.description}</p> : null}
    </div>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default SectionTitle;
