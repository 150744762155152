import React, { useState, useEffect, useContext, useCallback } from "react";
import Axios from "axios";
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Tabs,
  Tab,
  InputAdornment,
} from "@material-ui/core";

import classes from "../Admin.module.css";
import { API } from "../../../configs/api";
import { ErrorContext } from "../../../context/ErrorContext";
import OrderCard from "./OrderCard/OrderCard";
import OrderForm from "./OrderForm/OrderForm";
import useTable from "../../Templates/useTable";
import Popup from "../../Templates/Popup";
import Notification from "../../Templates/Notification";
import { Input } from "../../Controls";
import { Search } from "@material-ui/icons";

const headCells = [{ id: "actions", label: "", disableSorting: true }];

const ListOrders = (props) => {
  const [orders, setOrders] = useState([]);
  const [activeTabOrders, setActiveTabOrders] = useState(orders);
  const [statuses, setStatuses] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const [orderToView, setOrderToView] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const viewError = useContext(ErrorContext).activateError;

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    setPage,
  } = useTable(activeTabOrders, headCells, filterFn);

  const updateOrders = useCallback(() => {
    Axios.get(API.orders.route)
      .then((response) => {
        setOrders(response.data);
      })
      .catch((error) => {
        viewError(true);
      });
  }, [viewError]);

  useEffect(() => {
    // get orders
    updateOrders();

    // get order statuses
    Axios.get(API.orders.getOrderStatuses)
      .then((response) => {
        setStatuses(response.data);
      })
      .catch((error) => {
        viewError(true);
      });
  }, [updateOrders, viewError]);

  useEffect(() => {
    let currentTabOrders = [];
    if (activeTab === "All") {
      currentTabOrders = orders;
    } else {
      currentTabOrders = orders.filter(
        (order) => order.orderStatus === activeTab
      );
    }
    setActiveTabOrders(currentTabOrders);
    setPage(0);
  }, [orders, activeTab]);

  const updateOrderToView = () => {
    Axios.get(API.orders.route + orderToView.id)
      .then((response) => {
        setOrderToView(response.data);
      })
      .catch((error) => {
        viewError(true);
      });
  };

  const openInPopup = (item) => {
    setOrderToView(item);
    setOpenPopup(true);
  };

  const handleSearch = (e) => {
    let value = e.target.value.toLowerCase();
    setFilterFn({
      fn: (items) => {
        if (value === "") {
          return items;
        } else {
          return items.filter(
            (item) =>
              String(item.id).toLowerCase().includes(value) ||
              String(item.contactName).toLowerCase().includes(value) ||
              String(item.contactEmail).toLowerCase().includes(value) ||
              String(item.contactPhone).toLowerCase().includes(value) ||
              String(item.orderStatus).toLowerCase().includes(value) ||
              String(item.totalPrice).toLowerCase().includes(value)
          );
        }
      },
    });
    setPage(0);
  };

  const onUpdateOrder = () => {
    // setOpenPopup(false);
    setOpenForm(false);
    setNotification({
      isOpen: true,
      message: "Updated Successfully",
      type: "success",
    });
    updateOrders();
    updateOrderToView();
  };

  return (
    <div className={classes.AdminList}>
      <Paper className={classes.PaperContent} elevation={0}>
        <Toolbar className={classes.Toolbar}>
          <Input
            label="Search"
            className={classes.SearchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Toolbar>
        {/* tabs go here */}
        <Tabs
          value={activeTab}
          onChange={(event, value) => {
            setActiveTab(value);
          }}
          className="mt-3"
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab value={"All"} label={"All"} />
          {statuses.map((status) => (
            <Tab
              value={status.name}
              label={status.name}
              key={`${status.name}-${status.id}`}
            />
          ))}
        </Tabs>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item, index) => (
              <TableRow key={"order-" + item.id} hover={true}>
                <TableCell>
                  <OrderCard
                    order={item}
                    openInPopup={openInPopup}
                    setOpenForm={setOpenForm}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>

      <Popup
        title={"Order: #" + (orderToView ? orderToView.id : null)}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <OrderCard order={orderToView} setOpenForm={setOpenForm} full={true} />
      </Popup>

      <Popup
        title={"Order: #" + (orderToView ? orderToView.id : null)}
        openPopup={openForm}
        setOpenPopup={setOpenForm}
      >
        <OrderForm
          order={orderToView}
          onUpdateOrder={onUpdateOrder}
          statuses={statuses}
          // setOrder={setOrderToView}
        />
      </Popup>

      <Notification
        notification={notification}
        setNotification={setNotification}
      />
    </div>
  );
};

export default ListOrders;
