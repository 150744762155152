import React from "react";
import { Link } from "react-router-dom";

import classes from "../../ListSubCategories/SubCategoryCard/SubCategoryCard.module.css";
import defaultImage from "../../../assets/images/imgplaceholder.jpg";

const VendorCard = (props) => {
  const { vendor } = props;

  return (
    <div className={classes.SubCategoryCard}>
      <div className={classes.CardContent}>
        <div className={classes.CardCover}>
          <Link to={"/vendor/" + vendor.id}>
            <img
              src={vendor.profilePicture ? vendor.profilePicture : defaultImage}
              alt={vendor.firstName}
              className="img-fluid"
            />
          </Link>
        </div>
        <div className={classes.Description}>
          <h3>{vendor.firstName}</h3>
          <Link to={"/vendor/" + vendor.id} className={classes.Animated}>
            Shop Now!
          </Link>
        </div>
      </div>
    </div>
  );
};

export default VendorCard;
