import React from "react";

import VendorCard from "./VendorCard/VendorCard";

const ListVendors = (props) => {
  return (
    <div className="row justify-content-around">
      {props.vendors.map((vendor) => (
        <div className="col-12 col-lg-6 col-xl-3" key={"vendor" + vendor.id}>
          <VendorCard vendor={vendor} />
        </div>
      ))}
    </div>
  );
};

export default ListVendors;
