import React, { useContext } from "react";
import { Link } from "react-router-dom";

import classes from "./Navbar.module.css";
import { CartContext } from "../../../context/CartContext";
import Logo from "../../Logo/Logo";
import NavItems from "../NavItems/NavItems";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

const Navbar = (props) => {
  const cartCount = useContext(CartContext).totalCount;

  return (
    <header className={classes.Navbar}>
      <div className="container-fluid">
        <div className="row justify-content-between align-items-center">
          <div className="col-6 col-md-2 text-center">
            <Link to="/">
              <Logo height="8rem" />
            </Link>
          </div>
          <div className={["col-8", classes.NavItems].join(" ")}>
            <NavItems
              closeSideDrawer={props.closeSideDrawer}
              categories={props.categories}
            />
          </div>
          <div className="col-6 col-md-2">
            <div className={classes.Tools}>
              <div
                className={[classes.ToggleSideDrawer, classes.Icon].join(" ")}
                onClick={props.toggleSideDrawer}
              >
                <FontAwesomeIcon icon={faBars} />
              </div>
              {/* <div className={classes.Icon}>
                <FontAwesomeIcon icon={faSearch} />
              </div> */}
              <div className={classes.Icon} onClick={props.toggleSideCart}>
                {cartCount > 0 && (
                  <div className={classes.CartCount}>{cartCount}</div>
                )}
                <FontAwesomeIcon icon={faShoppingCart} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
