import React from "react";
import { Snackbar, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    top: theme.spacing(9),
  },
}));

const Notification = (props) => {
  const { notification, setNotification } = props;
  const muiClasses = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({
      ...notification,
      isOpen: false,
    });
  };

  return (
    <Snackbar
      className={muiClasses.root}
      open={notification.isOpen}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleClose}
    >
      <Alert severity={notification.type} onClose={handleClose}>
        {notification.message}
      </Alert>
    </Snackbar>
  );
};

export default React.memo(Notification);
