import React, { useState, useContext } from "react";
import Axios from "axios";
import { makeStyles } from "@material-ui/core";

import classes from "../../Admin.module.css"
import { API } from "../../../../configs/api";
import { ErrorContext } from "../../../../context/ErrorContext";
import { MuiButton } from "../../../Controls";
import Button from "../../../UI/Button/Button";
import Loading from "../../../UI/Loading/Loading";
import defaultImage from "../../../../assets/images/imgplaceholder.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const initialImage = {
  imageFile: null,
  imageSrc: defaultImage,
};

const FeaturedImageForm = (props) => {
  const { onAddImage } = props;
  const muiClasses = useStyles();

  const [image, setImage] = useState(initialImage);
  const viewError = useContext(ErrorContext).activateError;
  const [loading, setLoading] = useState(false);

  const showPreview = (e) => {
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (file) => {
        if (reader.readyState === 2) {
          setImage({
            imageFile: imageFile,
            imageSrc: file.target.result,
          });
        }
      };
      reader.readAsDataURL(imageFile);
    } else {
      setImage({
        imageFile: null,
        imageSrc: defaultImage,
      });
    }
  };

  const resetForm = () => {
    setImage(initialImage);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (image.imageFile) {
      const formData = new FormData();
      formData.append("imageFile", image.imageFile);

      Axios.post(API.featuredImages.route, formData)
        .then((response) => {
          setLoading(false);
          resetForm();
          onAddImage();
        })
        .catch((error) => {
          setLoading(false);
          viewError(true);
        });
    }
  };

  return (
    <div className={classes.AdminForm}>
      <div className="inputsContainer row">
        <div className="col-12">
          <form onSubmit={handleSubmit}>
            <div className="card text-center">
              <div className={classes.CoverCard}>
                <img
                  src={image.imageSrc}
                  className="card-img-top"
                  alt="featured"
                />
              </div>
              <div className="card-body">
                <div className={muiClasses.root}>
                  <input
                    type="file"
                    id="contained-button-file"
                    name="image"
                    accept=".jpg, .jpeg, .png"
                    className={muiClasses.input}
                    onChange={showPreview}
                  />
                  <label htmlFor="contained-button-file">
                    <MuiButton
                      variant="contained"
                      color="primary"
                      component="span"
                      text="Choose Image"
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="row pt-5 justify-content-center">
              <div className="col-12 text-center">
                <Button type="submit">
                  <strong>Add Image</strong>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default FeaturedImageForm;
