import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";

import classes from "../../Admin.module.css";
import { API } from "../../../../configs/api";
import { ErrorContext } from "../../../../context/ErrorContext";
import defaultImage from "../../../../assets/images/imgplaceholder.jpg";
import Button from "../../../UI/Button/Button";
import Loading from "../../../UI/Loading/Loading";

const initialCategory = {
  id: "",
  name: "",
  cover: null,
  coverSrc: defaultImage,
};

const CategoryForm = (props) => {
  const { id } = props.match.params;
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(initialCategory);
  const viewError = useContext(ErrorContext).activateError;

  useEffect(() => {
    if (id) {
      if (isNaN(+id) || +id <= 0) {
        props.history.goBack();
      }

      Axios.get(API.categories.route + id)
        .then((response) => {
          setEditMode(true);
          setCategory({
            id: response.data.id,
            name: response.data.name,
            cover: response.data.cover,
            coverSrc: response.data.cover,
          });
        })
        .catch((error) => {
          viewError(true);
        });
    }
  }, [viewError, props.history, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setCategory({
      ...category,
      [name]: value,
    });
  };

  const showPreview = (e) => {
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (file) => {
        if (reader.readyState === 2) {
          setCategory({
            ...category,
            cover: imageFile,
            coverSrc: file.target.result,
          });
        }
      };
      reader.readAsDataURL(imageFile);
    } else {
      setCategory({
        ...category,
        cover: null,
        coverSrc: defaultImage,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("name", category.name);
    formData.append("photoFile", category.cover);
    if (!editMode) {
      Axios.post(API.categories.route, formData)
        .then((response) => {
          setLoading(false);
          const currentPath = props.location.pathname;
          const parentPath = currentPath.substring(
            0,
            currentPath.lastIndexOf("/")
          );
          props.history.push(parentPath);
        })
        .catch((error) => {
          setLoading(false);
          viewError(true);
        });
    } else {
      formData.append("id", category.id);
      Axios.put(API.categories.route + category.id, formData)
        .then((response) => {
          setLoading(false);
          const currentPath = props.location.pathname;
          const parentPath = currentPath.substring(
            0,
            currentPath.lastIndexOf("/")
          );
          props.history.push(parentPath);
        })
        .catch((error) => {
          setLoading(false);
          viewError(true);
        });
    }
  };

  return (
    <div className={classes.AdminForm}>
      <div>
        <h2>Add New Category</h2>
      </div>
      <div className="inputsContainer row">
        <div className="col-12">
          <form onSubmit={handleSubmit}>
            <div className="row pt-5 justify-content-around align-items-center">
              <div className="col-4 form-group">
                <label htmlFor="name">
                  <strong>Name</strong>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name"
                  className="form-control"
                  value={category.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-7">
                <div className="card text-center">
                  <div className={classes.CoverCard}>
                    <img
                      src={category.coverSrc}
                      className="card-img-top"
                      alt="category cover"
                    />
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="cover">
                        <strong>Cover</strong>
                      </label>
                      <input
                        type="file"
                        id="cover"
                        name="cover"
                        accept=".jpg, .jpeg, .png"
                        className="form-control-file"
                        onChange={showPreview}
                        required={!editMode}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-5 justify-content-center">
              <div className="col-12 text-center">
                <Button type="submit">
                  <strong>
                    {editMode ? "Update Category" : "Add Category"}
                  </strong>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default CategoryForm;
