import React, { useState, useEffect, useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Axios from "axios";

import { AuthContext } from "../../context/AuthContext";
import { API } from "../../configs/api";
import { Admin_Role, Vendor_Role } from "../../configs/constants";
import ErrorModal from "../../components/UI/Modal/ErrorModal";
import TopHeader from "../../components/Navigation/TopHeader/TopHeader";
import Navbar from "../../components/Navigation/Navbar/Navbar";
import SideCart from "../../components/Cart/SideCart/SideCart";
import SideDrawer from "../../components/Navigation/SideDrawer/SideDrawer";
import Footer from "../../components/Footer/Footer";
import Admin from "../Admin/Admin";
import Auth from "../Auth/Auth";
import MyAccount from "../MyAccount/MyAccount";
import MyVendor from "../../components/MyVendor/MyVendor";
import Home from "../Home/Home";
import Cart from "../../components/Cart/Cart/Cart";
import Checkout from "../../components/Checkout/Checkout";
import Product from "../../components/Product/Product";
import SubCategories from "../SubCategories/SubCategories";
import Products from "../Products/Products";
import Categories from "../Categories/Categories";
import Vendor from "../Vendor/Vendor";
import Vendors from "../Vendors/Vendors";
import ThanksMessage from "../../components/Checkout/ThanksMessage/ThanksMessage";
import Terms from "../TermsOfService/Terms";

const Layout = () => {
  const [categories, setCategories] = useState([]);
  const [storeInfo, setStoreInfo] = useState({
    email: "",
    phone: "",
    location: "",
    about: "",
    terms: "",
  });
  const [showSideCart, setShowSideCart] = useState(false);
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const isAuthenticated = useContext(AuthContext).isAuthenticated;
  const userRoles = useContext(AuthContext).userData.roles;
  const isAdmin = userRoles.includes(Admin_Role);
  const isVendor = userRoles.includes(Vendor_Role);

  useEffect(() => {
    // categories
    Axios.get(API.categories.withSub)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {});

    // store info
    Axios.get(API.storeInfo.route)
      .then((response) => {
        setStoreInfo(response.data);
      })
      .catch((error) => {});
  }, []);

  const toggleSideCart = () => {
    setShowSideCart((prevState) => {
      return !prevState;
    });
  };

  const toggleSideDrawer = () => {
    setShowSideDrawer((prevState) => {
      return !prevState;
    });
  };

  return (
    <div>
      <ErrorModal />
      <TopHeader
        storeInfo={storeInfo}
        isAuthenticated={isAuthenticated}
        isAdmin={isAdmin}
      />
      <Switch>
        {isAdmin ? <Route path="/admin" component={Admin} /> : null}
        <React.Fragment>
          <Navbar
            toggleSideCart={toggleSideCart}
            toggleSideDrawer={toggleSideDrawer}
            closeSideDrawer={() => setShowSideDrawer(false)}
            categories={categories}
          />
          <SideCart
            isOpen={showSideCart}
            close={() => setShowSideCart(false)}
          />
          <SideDrawer
            isOpen={showSideDrawer}
            close={() => setShowSideDrawer(false)}
            categories={categories}
          />
          <Switch>
            {/* needs to be adjusted with redirections */}
            {!isAuthenticated ? (
              <Route path="/auth" component={Auth} />
            ) : (
              <Route path="/myaccount" component={MyAccount} />
            )}
            {isAuthenticated ? (
              <Route path="/myaccount" component={MyAccount} />
            ) : (
              <Route path="/auth" component={Auth} />
            )}
            {isAuthenticated && isVendor && (
              <Route path="/myvendor" component={MyVendor} />
            )}
            <Route path="/cart" component={Cart} />
            <Route path="/checkout" component={Checkout} />            
            <Route path="/created-successfully" component={ThanksMessage} />
            <Route path="/termsofservice" component={Terms} />
            <Route path="/product/:productId" component={Product} />
            <Route path="/category/all/:categoryId" component={Products} />
            <Route path="/subcategory/:subCategoryId" component={Products} />
            <Route path="/category/:categoryId" component={SubCategories} />
            <Route path="/vendor/:vendorId" component={Vendor} />
            <Route path="/vendors" component={Vendors} />
            <Route
              path="/categories"
              render={(props) => (
                <Categories {...props} categories={categories} />
              )}
            />
            <Route
              path="/home"
              render={(props) => <Home {...props} categories={categories} />}
            />
            <Route
              path="/"
              render={(props) => <Home {...props} categories={categories} />}
            />
          </Switch>
          <Footer storeInfo={storeInfo} categories={categories} />
        </React.Fragment>
      </Switch>
    </div>
  );
};

export default Layout;
