import React from "react";
import ReactLoading from "react-loading";

import classes from "./Loading.module.css";

const Loading = (props) => (
  <div className={classes.LoaderBackdrop}>
    <ReactLoading
      type={"spin"}
      color={"black"}
      height={"5%"}
      width={"5%"}
      className={classes.Loader}
    />
  </div>
);

export default Loading;
