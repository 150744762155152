import React from "react";
import { FormControl, FormControlLabel, Checkbox } from "@material-ui/core";

export const MuiCheckbox = (props) => {
  const { name, label, value, checked, onChange } = props;

  return (
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            name={name}
            value={value}
            checked={checked}
            onChange={(e) => onChange(e)}
          />
        }
        label={label}
      />
    </FormControl>
  );
};
