import React from "react";
import { Link } from "react-router-dom";

import classes from "./SubCategoryCard.module.css";

const SubCategoryCard = (props) => {
  return (
    <div className={classes.SubCategoryCard}>
      <div className={classes.CardContent}>
        <div className={classes.CardCover}>
          <Link to={"/subcategory/" + props.subCategory.id}>
            <img
              src={props.subCategory.cover}
              alt={props.subCategory.name}
              className="img-fluid"
            />
          </Link>
        </div>
        <div className={classes.Description}>
          <h3>{props.subCategory.name}</h3>
          <Link
            to={"/subcategory/" + props.subCategory.id}
            className={classes.Animated}
          >
            Shop Now!
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SubCategoryCard;
