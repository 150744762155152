import React, { useContext } from "react";
import { withRouter } from "react-router-dom";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { AuthContext } from "../../../context/AuthContext";

import defaultImage from "../../../assets/images/imgplaceholder.jpg";

const VendorProfile = (props) => {
  const { vendor } = props;
  const vendorId = useContext(AuthContext).userData.id;
  const myProfile = vendor.id === vendorId;

  const gotoEdit = () => {
    props.history.push("/myaccount/edit-contant-info");
  };

  const gotoVendors = () => {
    props.history.push("/vendors");
  };

  return (
    <Card elevation={0}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt="Profile Picture"
          height="auto"
          image={vendor.profilePicture ? vendor.profilePicture : defaultImage}
          title={vendor.firstName}
        />
        <CardContent className="text-center">
          <Typography gutterBottom variant="h4" component="h2" color="primary">
            {vendor.firstName}
          </Typography>
          {myProfile && (
            <Typography gutterBottom variant="h5" component="h2">
              {vendor.phone}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
      <CardActions className="justify-content-center pb-5">
        {myProfile && (
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={gotoEdit}
          >
            Edit
          </Button>
        )}
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={gotoVendors}
        >
          All Vendors
        </Button>
      </CardActions>
    </Card>
  );
};

export default withRouter(VendorProfile);
